import { Box, Chip, Grid, Stack } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import RangeSelectorDatetime from '../common/RangeSelectorDatetime'
import { useGetFilteredStudentsMutation, useGetFilteredSubmissionsMutation, useGetFilteredTransactionsMutation } from '../../services/analytics.service'
import { Submission } from '../../types/submissions'
import { Moment } from 'moment'
import StudentsSubmissionsChart from './StudentsSubmissionsChart'
import TransactionsChart from './TransactionsChart'
import StudentSubmissionsDonut from './StudentSubmissionsDonut'
import TransactionsDonut from './TransactionsDonut'
const chartStyle={height:'100%',width:'100%',flexDirection:'column'
,bgcolor:'#ffffff',
border: '1px solid',
borderRadius: '8px',
p:2,

borderColor: "#e6ebf1",
}
const  dataDounter= {
    options: {},
    series: [44, 55, 41, 17, 15],
    labels: ['A', 'B', 'C', 'D', 'E']
  }
const dataBar={
options: {
  chart: {
    id: "basic-bar"
  },
  xaxis: {
    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
  }
},
series: [
  {
    name: "series-1",
    data: [30, 40, 45, 50, 49, 60, 70, 91]
  }
]
};
function Analytics() {

const  [dataSource, setDataSource] = useState<string[]>(['students']);
    const [getStudents]=useGetFilteredStudentsMutation();
   const [getSubmissions]=useGetFilteredSubmissionsMutation();
    const [getTransactions]=useGetFilteredTransactionsMutation()
const [pipeline, setPipeline] = useState({
    ranges:[{
        field: "createdAt",
      start: "2022-01-01T00:00:00.000Z",
      end: "2024-01-30T00:00:00.000Z" 
    },
],
"dynamicFilters": [],
"groupOptions": {
 
}
})



const buildFilteringPipeline=(ranges:any)=>{

}


  return (
    <Grid container height={"100%"} flexDirection={"row"} spacing={1}>
      <Grid container item height={"50%"} gap={1} md={12}>
        <TransactionsChart />
        <TransactionsDonut />
      </Grid>
      <Grid container item height={"50%"} gap={1} md={12}>
        <StudentsSubmissionsChart />

        <StudentSubmissionsDonut />
      </Grid>
    </Grid>
  );
}

export default Analytics