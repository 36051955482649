import {
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import { TASK_STATUS, TASK_URGENCY, Task } from "../../types/tasks";
import {
  Box,
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton, styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import moment from "moment";
import { User } from "../../types/users";
import FormBuilder from "../controls/FormBuilder";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "80%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const initialTaskData: Task = {
  name: "Task Name",
  description: "",
  status: "To Do",
  dueDate: moment(),
  urgency: "low",
  assignedTo: "",
  createdBy: "",
  refNo: "T-0000",
  _id: "",
};
function TaskForm({
  selectedTask,
  users,
  handleClose,
  open,
  edit,
  submitHanlder,
}: {
  selectedTask: Task | null;
  users: User[];
  handleClose: any;
  open: boolean;
  edit: boolean;
  submitHanlder: any;
}) {
  const [taskData, setTaskData] = useState<Task>(initialTaskData);
  const [shouldDisable, setShouldDisable] = useState<boolean>(false);
  const currentUser = useSelector((state: RootState) => state.user);
  useLayoutEffect(() => {
    if (selectedTask) setTaskData(selectedTask);
    if (currentUser.role === "admin") setShouldDisable(false);
    if (!edit) return setShouldDisable(false);
    if (selectedTask?.createdBy !== currentUser._id) setShouldDisable(true);
    return () => {
      setTaskData(initialTaskData);
    };
  }, [selectedTask, currentUser, edit]);

  const controls = useMemo(
    () => [
      {
        type: "input",
        control: {
          additional: {
            fullWidth: true,
            required: true,
          },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "name",
          name: "name",
          label: "Name",
        },
      },
      {
        type: "datetime",
        control: {
          timezone: "system",
          label: "Due Date",
          id: "dueDate",
          name: "dueDate",
          additional: {
            fullWidth: true,
            required: true,
            disabled: shouldDisable,
          },
        },
      },
      {
        type: "select",
        control: {
          otherProps: { fullWidth: true },
          additional: {
            fullWidth: true,
            required: false,
            disabled: shouldDisable,
          },
          id: "assignedTo",
          name: "assignedTo",
          label: "Assigned To",
          options: [
            ...users.map((user) => ({ label: user.username, value: user._id })),
          ],
        },
      },
      {
        type: "radio",
        control: {
          otherProps: { fullWidth: true },

          additional: {
            row: true,
            sx: { textTransform: "capitalize" },
            required: true,
          },
          id: "status",
          name: "status",
          label: "Status",
          options: [
            ...TASK_STATUS.map((task) => ({ label: task, value: task })),
          ],
        },
      },
      {
        type: "radio",
        control: {
          otherProps: { fullWidth: true, disabled: shouldDisable },

          additional: {
            row: true,
            sx: { textTransform: "capitalize" },
            required: true,
          },
          id: "urgency",
          name: "urgency",
          label: "Urgency",
          options: [
            ...TASK_URGENCY.map((task) => ({ label: task, value: task })),
          ],
        },
      },
      {
        type: "input",
        control: {
          additional: {
            fullWidth: true,
            required: true,
            multiline: true,
            row: 4,
          },
          otherProps: { fullWidth: true, disabled: shouldDisable },

          id: "description",
          name: "description",
          label: "Description",
        },
      },
    ],
    [users, shouldDisable]
  );
  return (
    <StyledDialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {taskData.name}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik initialValues={taskData} onSubmit={submitHanlder}>
          {({ handleSubmit }) => (
            <Box
              component={"form"}
              id="mytask"
              onSubmit={(e) => handleSubmit(e)}>
              <FormBuilder controls={controls} />
            </Box>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form="mytask">
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default TaskForm;
