import React, { useEffect, useState } from "react";
import { Box, styled} from '@mui/material';
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Drawer";
import Header from "../../components/Header";
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // overflow:'hidden',

  overflowX: "hidden",
  width: "100%",
  padding: theme.spacing(3),
  paddingTop: theme.spacing(10),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
function MainLayout() {
  const [sidebar, setSidebar] = useState(true);
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  
  }, [sidebar])
  
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
      }}>
      <Header
        sidebar={sidebar}
        handleSidebar={() => setSidebar((prev) => !prev)}
      />
      <Sidebar sidebar={sidebar} />
      <Main open={sidebar}>
        <Outlet />
      </Main>
    </Box>
  );
}

export default MainLayout;
