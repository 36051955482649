import { CHIPS_COLOR, Student } from "../../types/students";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  Email as EmailIcon,
  AddBox,
  Assistant,
  Delete,
  Edit,
  FileDownload,
  Folder,
  Note as NoteIcon,
  Person,
  WhatsApp,
} from "@mui/icons-material";
import StudentForm from "./StudentsForm";
import { useEffect, useMemo, useState } from "react";
import { useGetUsersQuery } from "../../services/users.service";
import {
  useCreateStudentMutation,
  useDeleteStudentMutation,
  useUpdateStudentMutation,
} from "../../services/students.service";
import StudentAdditionalInfo from "./StudentAdditionalInfo";
import { User } from "../../types/users";
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { download, generateCsv, mkConfig } from "export-to-csv";
import moment from "moment";
import { exportActions, exportMenuItems } from "../common/ExportMenu";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../util/helper";
import vCard from "vcf";
import EmailEditor from "../common/EmailEditor";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useSendEmailMutation } from "../../services/email.service";
import { Email } from "../../types/email";
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: '#f8f8f9',
//     color: theme.palette.common.black,
//   },

// }));

const acitonItems = [
  {
    icon: <Person color="primary" fontSize="small" />,
    action: "showStudentProfile",
    label: "Show Profile",
  },
  {
    icon: <Edit color="primary" fontSize="small" />,
    action: "handleEdit",
    label: "Edit",
  },
  {
    icon: <WhatsApp color="success" fontSize="small" />,
    action: "whatsApp",
    label: "WhatsApp",
  },
  {
    icon: <EmailIcon color="success" fontSize="small" />,
    action: "handleSendEmail",
    label: "Email",
  },
  {
    icon: <Delete fontSize="small" color="error" />,
    action: "handleDelete",
    label: "Delete",
  },
];
const nameAndDegree = (row: Student) => {
  const nameArray = row?.fullName?.split(" ");
  const firstName = nameArray?.[0];
  const lastName =
    nameArray.length > 2 ? nameArray?.[nameArray.length - 1] : "";
  const university = row?.university ? row?.university : "";
  return `${firstName} ${lastName} - ${university}`;
};
const actionMenu = (
  items: any[],
  row: MRT_Row<Student>,
  actions: any,
  closeMenu: any
): React.ReactNode[] => {
  return items.map((item, idx) => (
    <MenuItem
      key={idx}
      onClick={() => {
        actions[item.action](row);
        closeMenu();
      }}
      sx={{ m: 0 }}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      {item.label}
    </MenuItem>
  ));
};

function StudentsTable({ rows, users }: { rows: Student[]; users: User[] }) {
  const { data, error, isLoading } = useGetUsersQuery("users");
  const [details, setDetails] = useState<{
    type: string;
    student: Student;
  } | null>(null);
  const [createStudent] = useCreateStudentMutation();
  const [updateStudent] = useUpdateStudentMutation();
  const [deleteStudent] = useDeleteStudentMutation();
  const [sendEmail] = useSendEmailMutation();
  const [selectedRow, setSelectedRow] = useState<Student | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [exportMenuEl, setExportMenuEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState<Student[]>(rows);
  const [openEmail, setOpenEmail] = useState<boolean>(false);
  const currentUser = useSelector((state: RootState) => state.user);
  const isAdmin = currentUser.role === "admin";
  const [filter, setFilter] = useState<string | "all" | "today" | "yesterday">(
    "all"
  );
  useEffect(() => {
    setDataTable(rows);
  }, [rows]);
  const additionalDetailsCell = (row: MRT_Row<Student>) => {
    const data = row.original;
    const stats = {
      notes: data.additionalData.notes.length,
      services: data.additionalData.services.length,
      documents: data.additionalData.documents.length,
    };
    return (
      <Stack
        sx={{
          cursor: "pointer",
        }}
        direction={"row"}
        gap={2}>
        <Badge
          component={"span"}
          onClick={() => handleOpenDetials(row, "notes")}
          badgeContent={stats.notes}
          color="primary">
          <NoteIcon color="primary" fontSize="small" />
        </Badge>
        <Badge
          component={"span"}
          onClick={() => handleOpenDetials(row, "documents")}
          badgeContent={stats.documents}
          color="secondary">
          <Folder color="secondary" fontSize="small" />
        </Badge>
        <Badge
          component={"span"}
          onClick={() => handleOpenDetials(row, "services")}
          badgeContent={stats.services}
          color="info">
          <Assistant color="info" fontSize="small" />
        </Badge>
      </Stack>
    );
  };
  const columns = useMemo<MRT_ColumnDef<Student>[]>(
    () => [
      {
        id: "refNo",
        accessorKey: "refNo",
        header: "Ref No",
        size: 30,
      },
      {
        id: "fullName",
        Cell: (cell) => nameAndDegree(cell.row.original),
        header: "Full Name",
      },
      {
        id: "passport.number",
        accessorKey: "passport.number",
        header: "Passport",
      },
      {
        id: "university",
        accessorKey: "university",
        header: "University",
      },
      {
        id: "program",
        accessorKey: "program",
        enableHiding: true,
        header: "Program",
      },
      {
        id: "degree",
        accessorKey: "degree",
        header: "Degree",
      },
      {
        id: "employee",
        accessorFn: (row) =>
          users.find((user) => user._id === row.employee)?.username,
        header: "Employee",
      },
      {
        id: "status",
        accessorFn: ({ status }) => (
          <Chip
            size="small"
            sx={{
              borderRadius: "8px",
            }}
            label={status}
            color={CHIPS_COLOR[status]}
          />
        ),
        header: "Status",
      },
      // {
      //   id: "phone",
      //   accessorKey: "phone",
      //   header: "Phone",
      // },
      {
        id: "additionalData",
        Cell: (cell) => additionalDetailsCell(cell.row),
        header: "Additional Data",
      },

      // {
      //   id: "email",
      //   accessorKey: "email",
      //   header: "Email",
      // },
      // {
      //   id: "nationality",
      //   accessorKey: "nationality",
      //   header: "Nationality",
      // },
      // {
      //   id: "commission",
      //   accessorKey: "commission",
      //   header: "Commission",
      // },
      // {
      //   id: "country",
      //   accessorKey: "country",
      //   header: "Country",
      // },
      {
        id: "createdAt",
        accessorFn: ({ createdAt }) => (
          <>
            {formatDate(createdAt)}
            <br />
            {moment(createdAt).fromNow()}
          </>
        ),
        header: "Created At",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportRows = (dataTable: MRT_Row<Student>[]) => {
    let rowData = dataTable.map((row) => row.original);
    rowData = rowData.map((data) => ({
      ...data,
      employee: users.find((user) => user._id === data.employee)?.username,
      passport: JSON.stringify(data.passport),
    })) as any;
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    let rowData = dataTable.map((data) => ({
      ...data,
      employee: users.find((user) => user._id === data.employee)?.username,
      passport: JSON.stringify(data.passport),
    })) as any;
    rowData = generateCsv(csvConfig)(rowData);
    download(csvConfig)(rowData);
  };
  const table = useMaterialReactTable({
    columns,
    data: dataTable, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableSelectAll: true,
    enableMultiRowSelection: true,
    getRowId: (originalRow) => originalRow._id,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowSelection: true,
    enableFullScreenToggle: false,
    enableRowActions: true,

    initialState: {
      density: "compact",
      showColumnFilters: false,
      showGlobalFilter: true,
      columnVisibility: {
        program: false,
        degree: false,
        additionalData: false,
        email: false,
        nationality: false,
        commission: false,
        country: false,
        createdAt: false,
      },
      columnPinning: { left: ["mrt-row-select", "mrt-row-actions"] },
    },
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 10, //adjust the size of the row select column
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: {
          xs: "10vh",
          sm: "45vh",
          md: "50vh",
          lg: "70vh",
          xl: "75vh",
        },
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
      },
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      ...actionMenu(
        acitonItems,
        row,
        {
          handleEdit,
          handleDelete,
          handleOpenDetials,
          whatsApp,
          handleSendEmail,
          showStudentProfile,
        },
        closeMenu
      ),
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box>
        <ButtonGroup
          sx={{
            flexWrap: "wrap",
          }}>
          <Select size="small" value={filter} onChange={filterData}>
            <MenuItem value={"all"}>All Data</MenuItem>
            <MenuItem value={"today"}>Today</MenuItem>
            <MenuItem value={"yesterday"}>Yesterday</MenuItem>
          </Select>
          <Button startIcon={<AddBox />} onClick={handleCreate}>
            Create
          </Button>
          <Button
            id="demo-positioned-button"
            aria-controls={
              Boolean(exportMenuEl) ? "demo-positioned-menu" : undefined
            }
            aria-haspopup="true"
            startIcon={<FileDownload />}
            aria-expanded={Boolean(exportMenuEl) ? "true" : undefined}
            onClick={(event: any) => {
              setExportMenuEl(event.target);
            }}>
            Export
          </Button>
        </ButtonGroup>
      </Box>
    ),
  });
  const handleSendEmail = (row: MRT_Row<Student>) => {
    setSelectedRow(row.original);
    setOpenEmail(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setEdit(false);
    setSelectedRow(null);
  };
  const handleOpenDetials = (row: MRT_Row<Student>, type: string) => {
    setDetails({ type: type, student: row.original });
  };
  const handleCloseDetials = () => {
    setDetails(null);
  };
  const handleGetVCard = async (dataTable: MRT_Row<Student>[]) => {
    const data = dataTable.map((d) => d.original);
    let cardString = "";
    data.forEach((item) => {
      const card = new vCard();
      card.add("fn", item.fullName);
      card.add("tel", item.phone);
      card.add("email", item.email);
      card.add("adr", item.country);
      card.add("title", item.degree);
      card.add("org", item.university);
      cardString += card.toString() + "\n";
    });
    const blob = new Blob([cardString], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const cardName = new Date().toISOString();
    a.download = `${"card-"}${cardName}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    reader.onloadend = async function () {
      let base64String = reader.result;
      const emailBody: Email = {
        message: {
          from: `${currentUser?.username} <${currentUser?.email}>`,
          to: `${currentUser?.username} <${currentUser?.email}>`,
          text: `${"card-"}${cardName}.vcf`,
          subject: `${"card-"}${cardName}.vcf`,
          attachments: [
            {
              filename: `${"card-"}${cardName}.vcf`,
              path: base64String,
            },
          ],
        },
        createdBy: currentUser._id,
        status: "send",
      };
      await sendEmail(emailBody);
    };
  };
  const filterData = (e: SelectChangeEvent) => {
    const value = e.target.value;
    const refDay = value === "today" ? moment() : moment().subtract(1, "day");
    const filtered = rows.filter((item) => {
      const created = moment(item.createdAt);
      return created.isSame(refDay, "day");
    });
    setDataTable(filtered);
    setFilter(value);
    if (value === "all") {
      setDataTable(rows);
    }
  };
  const handleSubmitForm = (student: Student) => {
    for (const key in student) {
      if (student[key] === "" || student[key] === null) {
        delete student[key];
      }
      if (student[key] === "N/A") {
        student[key] = null;
      }
    }
    if (edit && selectedRow) {
      updateStudent(student)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    } else {
      createStudent(student)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    }
  };
  const showStudentProfile = (row: MRT_Row<Student>) => {
    navigate(`${row.original?._id}`);
  };
  const handleCreate = () => {
    setEdit(false);
    setSelectedRow(null);
    setOpenModal(true);
  };
  const handleEdit = (row: MRT_Row<Student>) => {
    setSelectedRow(row.original);
    setOpenModal(true);
    setEdit(true);
  };
  const whatsApp = (row: MRT_Row<Student>) => {
    const phone = row.original.phone;
    const href = `https://wa.me/${phone}`;
    const newwindow = window.open(href, "whatsApp", "height=800,width=900");
    if (window.focus!) {
      newwindow?.focus();
    }
    return false;
  };
  const handleDelete = async (row: MRT_Row<Student>) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to delete ${row?.original.fullName}`
        )
      ) {
        await deleteStudent(row.id);
        setSelectedRow(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {openEmail && (
        <EmailEditor
          open={openEmail}
          currentUser={currentUser}
          details={selectedRow}
          handleClose={() => setOpenEmail(false)}
        />
      )}
      <Menu
        anchorEl={exportMenuEl}
        open={Boolean(exportMenuEl)}
        onClose={() => {
          setExportMenuEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        {exportMenuItems<Student>(exportActions, table, {
                   ...(isAdmin && { handleExportData, handleExportRows }),
          handleGetVCard,
        })}
      </Menu>
      {details && (
        <StudentAdditionalInfo
          open={!!details}
          handleClose={handleCloseDetials}
          details={details}
        />
      )}
      <Grid
        container
        sx={{
          flexDirection: "column",
          gap: 2,
          height: "100%",
          flexWrap: "nowrap",
        }}>
        {(!isLoading || !error) && data && openModal && (
          <StudentForm
            submitHanlder={handleSubmitForm}
            edit={!!selectedRow}
            open={openModal}
            handleClose={handleClose}
            selectedStudent={selectedRow}
            users={data}
          />
        )}
        {/* <Paper sx={{ width: "100%", height: "100%", overflow: "scroll" }}> */}
        <MaterialReactTable table={table} />
        {/* </Paper> */}
      </Grid>
    </>
  );
}

export default StudentsTable;
