import { TCountryCode } from "countries-list";
import { MomentInput } from "moment";
import { AdditionalData } from "./additionalData";

export interface Document {
  name: string;
  status:
    | "initial"
    | "pending"
    | "whatsapped"
    | "emailed"
    | "registering"
    | "rejected"
    | "accepted"
    | "completed";
  submissionDate: MomentInput;
  details: string;
}

export interface Passport {
  number: string;
  issueDate: Date;
  expiryDate: Date;
  birthDate: Date;
}
export interface Submission {
  [key: string]:
    | string
    | Date
    | undefined
    | MomentInput
    | AdditionalData
    | Passport;
  refNo: string;
  passport: Passport;
  fullName: string;
  email: string;
  employee: string;
  nationality: string;
  country: TCountryCode;
  phone: string;
  degree: string;
  program: string;
  status: keyof typeof SUBMISSION_STATUS;
  additionalData: AdditionalData;
  createdAt?: Date;
  updatedAt?: Date;
  _id: string;
}
export const CHIPS_COLOR: {
  [key: string]:
    | "primary"
    | "warning"
    | "default"
    | "error"
    | "secondary"
    | "info"
    | "success";
} = {
  initial: "default",
  whatsapped: "info",
  emailed: "info",
  pending: "secondary",
  registering: "info",
  rejected: "error",
  accepted: "success",
  completed: "success",
};
export enum SUBMISSION_STATUS {
  "initial" = "initial",
  "whatsapped" = "whatsapped",
  "emailed" = "emailed",
  "pending" = "pending",
  "registering" = "registering",
  "rejected" = "rejected",
  "accepted" = "accepted",
  "completed" = "completed",
}
