import { FormControlLabel, Grid, MenuItem, Select, SelectChangeEvent, Stack, Switch } from '@mui/material'
import React, { useLayoutEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import RangeSelectorDatetime from '../common/RangeSelectorDatetime'
import moment, { Moment } from 'moment'
import { useGetFilteredStudentsMutation, useGetFilteredSubmissionsMutation } from '../../services/analytics.service'
import { ApexOptions } from 'apexcharts'
import { CheckBox } from '@mui/icons-material'
const chartStyle = {
  height: "100%",
  width: "100%",
  flexDirection: "column",
  bgcolor: "#ffffff",
  boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
  borderRadius: "8px",
  p: 2,
};

const groupByList = ["nationality", "country", "degree", "program", "status"];
function StudentSubmissionsDonut() {
  const [showLegend, setShowLegend] = useState(false);
  const [getStudents] = useGetFilteredStudentsMutation();
  const [getSubmissions] = useGetFilteredSubmissionsMutation();
  const [chartData, setChartData] = useState<ApexOptions>({
    legend: {
      show: showLegend,
      position: "right",
    },
    series: [],
    labels: [],
  });
  const [source, setSource] = useState("students");
  const [groupBy, setGroupBy] = useState("status");
  const [pipeline, setPipeline] = useState({
    ranges: [
      {
        field: "createdAt",
        start: moment().subtract(2, "months"),
        end: moment(),
      },
    ],
    dynamicFilters: [],
    groupOptions: {
      groupByField: groupBy,
    },
  });
  useLayoutEffect(() => {
    getData(source, pipeline);
  }, [pipeline, source]);
  const handleRangeChage = (range: {
    start: Moment | null;
    end: Moment | null;
  }) => {
    setPipeline({
      ...pipeline,
      ranges: [
        {
          field: "createdAt",
          start: range.start?.toISOString() as any,
          end: range.end?.toISOString() as any,
        },
      ],
    });
  };
  const handleChangeSource = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSource(value);
  };
  const handleChangeGroup = (e: SelectChangeEvent) => {
    let value = e.target.value;
    setGroupBy(value);
    const newPipeline = {
      ...pipeline,
      groupOptions: {
        groupByField: value,
      },
    };
    setPipeline(newPipeline);
  };
  const getData = (source: string, pipeline: any) => {
    if (source === "students") {
      getStudents(pipeline).then((res: any) => {
        if (res.error) return false;
        const data = res?.data;
        const series = data?.map((item: any) => item.count);
        const labels = data?.map((item: any) => item._id);
        setChartData({
          ...chartData,
          series: series,
          labels: labels,
        });
      });
    }
    if (source === "submissions") {
      getSubmissions(pipeline).then((res: any) => {
        if (res.error) return false;
        const data = res?.data;
        const series = data?.map((item: any) => item.count);
        const labels = data?.map((item: any) => item._id);
        setChartData({
          ...chartData,
          series: series,
          labels: labels,
        });
      });
    }
  };
  return (
    <Grid item container flex={1} sx={chartStyle}>
      <Grid item container gap={1}>
        <RangeSelectorDatetime onRangeChange={handleRangeChage} />
        <Stack direction={"row"} width={"100%"} spacing={1}>
          <Select
            fullWidth
            size="small"
            labelId="source"
            id="source"
            value={source}
            label="Source"
            onChange={handleChangeSource}>
            <MenuItem value={"students"}>Students</MenuItem>
            <MenuItem value={"submissions"}>Submissions</MenuItem>
          </Select>
          <Select
            fullWidth
            size="small"
            labelId="groupby"
            id="groupby"
            value={groupBy}
            label="Group By"
            sx={{ textTransform: "capitalize" }}
            onChange={handleChangeGroup}>
            {groupByList.map((group) => (
              <MenuItem
                sx={{ textTransform: "capitalize" }}
                key={group}
                value={group}>
                {group}
              </MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={showLegend}
                onChange={(e) => setShowLegend(e.target.checked)}
              />
            }
            label="Legend"
          />
        </Stack>
      </Grid>
      <Grid item flex={100}>
        <Chart
          options={{ ...chartData, legend: { show: showLegend } }}
          series={chartData.series}
          type="pie"
          height="100%"
        />
      </Grid>
    </Grid>
  );
}

export default StudentSubmissionsDonut