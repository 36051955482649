// interface taskProps {
//   // 👈 typing for the "romanNumber" object
//   [key: string]: string | Date | undefined;

import { MomentInput } from "moment";

// }
export interface Task {
  [key: string]: string | Date | undefined | MomentInput;
  name: string;
  description: string | "";
  status: "To Do" | "In Progress" | "Completed";
  dueDate: MomentInput;
  urgency: "low" | "medium" | "high";
  createdBy: string; // The user who created the task
  assignedTo: string; // The user to whom the task is assigned
  _id: string;
  refNo: string;
}

export const TASK_STATUS = ["To Do", "In Progress", "Completed"];

export const TASK_URGENCY = ["low", "medium", "high"];
export const CHIPS_COLOR: {
  [key: string]:
    | "primary"
    | "warning"
    | "default"
    | "error"
    | "secondary"
    | "info"
    | "success";
} = {
  low: "primary",
  medium: "warning",
  high: "error",
  "To Do": "info",
  "In Progress": "secondary",
  Completed: "success",
};
