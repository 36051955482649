import { Grid, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { Analytics, CardStats } from "../../types/analytics";
import Chart from "./Chart";
import { parseData } from "./dashboard-helper";
import AnalyticCards from "./AnalyticCards";
import RecentSection from "./RecentSection";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

type Periods = "daily" | "monthly" | "weekly";

function Dashboard({ analytics }: { analytics: Analytics }) {
  const [period, setPeriod] = useState<Periods>("daily");
  // const  [chartData, setChartData] = useState<any[]>([]);
  const cardsData: CardStats = parseData(analytics, period);
  const role = useSelector((state: RootState) => state.user.role);
  const permissions = useSelector((state: RootState) => state.user.permissions);
  const allowedResources = permissions.map((res) => {
    if (res.resource === "tasks" && role === "admin") return false;
    if (res.actions.includes("READ")) return res.resource;
  });
  // useEffect(() => {
  //     const data=cardsData['students'][period].stats
  //     setChartData([generateChartData(data,'student')])

  // }, [analytics])

  const handleSelectPeriod = (e: SelectChangeEvent) => {
    const value = e.target.value as Periods;
    setPeriod(value);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        alignItems: "flex-start",
        justifyContent: "space-between",
        height: { lg: "80vh" },
        width: "100%",
      }}>
      <Grid
        gap={2}
        container
        height={"100%"}
        item
        width={"100%"}
        lg={8}
        xs={12}
        flexWrap={"nowrap"}
        direction={"column"}>
        <Grid item width={"100%"}>
          <AnalyticCards
            analytics={analytics}
            cardsData={cardsData}
            period={period}
            allowedResources={allowedResources}
          />
        </Grid>
        <Grid
          item
          sx={{
            height: "100%",
            width: "100%",
            bgcolor: "#ffffff",
            boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
            // "&:hover": {
            //   boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.15)",
            // },
            // border: "1px solid",
            borderRadius: "8px",
            p: 2,
            // borderColor: "#e6ebf1",
          }}>
          {
            <Chart
              handleSelectPeriod={handleSelectPeriod}
              period={period}
              data={cardsData}
            />
          }
        </Grid>
      </Grid>
      <Grid item container lg={4} xs={12} height={"100%"}>
        <RecentSection />
      </Grid>
    </Grid>
  );
}

export default Dashboard;
