import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material'
import { useFormikContext } from 'formik';

function TextField({control,otherProps}:{control:any,otherProps:any}) {
  const formik = useFormikContext();
  const fieldProps=formik.getFieldProps(control.name);
  return (
    <FormControl   variant={control.variant} {...otherProps}>
        <InputLabel htmlFor={control.id}>{control.label}</InputLabel>
        <Input
        {...fieldProps}
            {...control.additional}
          defaultValue={control.defaultValue}
        />
        {control?.errors?.map((error:any)=>(
            <FormHelperText id={control.id}>{error.message}</FormHelperText>
        ))}
      </FormControl>
  )
}

export default TextField