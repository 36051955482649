import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface INotificaion {
  message: string;
  index: number;
  title: string;
  type: "error" | "warning" | "info" | "success";
}

const initialState: INotificaion[] = [];

export const notificaionsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotificaion: (state, action: PayloadAction<INotificaion>) => {
      state.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addNotificaion } = notificaionsSlice.actions;

export default notificaionsSlice.reducer;
