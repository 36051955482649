import { Buffer } from "buffer";
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  AddBox,
  Archive,
  Delete,
  DocumentScanner,
  Edit,
  FileDownload,
  Paid,
  Preview,
  Receipt,
  Send,
} from "@mui/icons-material";
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Student } from "../../types/students";
import { User } from "../../types/users";
import { download, generateCsv, mkConfig } from "export-to-csv";
import moment, { Moment } from "moment";
import { exportActions, exportMenuItems } from "../common/ExportMenu";
import { fillPdfForm, formatDate } from "../../util/helper";
import { Invoice } from "../../types/invoices";
import {
  useCreateInvoicessMutation,
  useDeleteInvoicessMutation,
  useUpdateInvoicessMutation,
} from "../../services/invoices.service";
import InvoicesForm from "./InvoicesForm";
import InvoiceViwer from "./InvoiceViwer";
import RangeSelectorDatetime from "../common/RangeSelectorDatetime";
import InvoicePDF from "../../assets/docs/invoice_form.pdf";
import { PDFDocument } from "pdf-lib";
import { Email } from "../../types/email";
import { useSendEmailMutation } from "../../services/email.service";

const acitonItems = [
  {
    icon: <Edit color="primary" fontSize="small" />,
    action: "handleEdit",
    label: "Edit",
  },
  {
    icon: <Preview fontSize="small" color="secondary" />,
    action: "handlePreview",
    label: "Preview",
  },
  {
    icon: <Paid fontSize="small" color="success" />,
    action: "handlePaid",
    label: "Set as Paid",
  },
  {
    icon: <DocumentScanner fontSize="small" color="disabled" />,
    action: "handleDownload",
    label: "Download PDF",
  },
  {
    icon: <Send fontSize="small" color="disabled" />,
    action: "handleSendPdf",
    label: "Send PDF",
  },
  {
    icon: <Archive fontSize="small" color="warning" />,
    action: "handleArchive",
    label: "Archive",
  },
  {
    icon: <Delete fontSize="small" color="error" />,
    action: "handleDelete",
    label: "Delete",
  },
];
const actionMenu = (
  items: any[],
  row: MRT_Row<Invoice>,
  actions: any,
  closeMenu: any
): React.ReactNode[] => {
  return items.map((item, idx) => (
    <MenuItem
      key={idx}
      onClick={() => {
        actions[item.action](row);
        closeMenu();
      }}
      sx={{ m: 0 }}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      {item.label}
    </MenuItem>
  ));
};
function InvoicesTable({
  rows,
  students,
  users,
}: {
  rows: Invoice[];
  students: Student[];
  users: User[];
}) {
  const [createInvoice] = useCreateInvoicessMutation();
  const [updateInvoicen] = useUpdateInvoicessMutation();
  const [deleteInvoice] = useDeleteInvoicessMutation();
  const [selectedRow, setSelectedRow] = useState<Invoice | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCalModal, setCalOpenModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<any>();
  const [exportMenuEl, setExportMenuEl] = useState<null | HTMLElement>(null);
  const [dataTable, setDataTable] = useState<Invoice[]>(rows);
  const [sendEmail] = useSendEmailMutation();
  const [filter, setFilter] = useState<string | "all" | "today" | "yesterday">(
    "all"
  );
  const getStudent = useMemo<{ [key: string]: string }>(
    () =>
      students.reduce(
        (prev, cur) => ({ ...prev, [cur._id]: cur.fullName }),
        {}
      ),
    [students]
  );
  const getUser = useMemo<{ [key: string]: string }>(
    () =>
      users.reduce((prev, cur) => ({ ...prev, [cur._id]: cur.username }), {}),
    [users]
  );
  useEffect(() => {
    const data = rows.filter((item) => item.status !== "Archived");
    setDataTable(data);
  }, [rows]);

  const columns = useMemo<MRT_ColumnDef<Invoice>[]>(
    () => [
      {
        id: "createdAt",
        accessorFn: ({ createdAt }) => (
          <>
            {formatDate(createdAt)}
            <br />
            {moment(createdAt).fromNow()}
          </>
        ),
        header: "Created At",
      },
      {
        id: "refNo",
        accessorKey: "refNo",
        header: "Reference Number",
      },
      {
        id: "student",
        accessorFn: (row) => row.student && getStudent[row.student],
        header: "Student",
      },
      {
        id: "service",
        accessorKey: "service",
        header: "Service",
      },
      {
        id: "amount",
        accessorKey: "amount",
        header: "Amount",
      },
      {
        id: "paidAmount",
        accessorKey: "paidAmount",
        header: "Paid Amount",
      },
      {
        id: "owed",
        accessorFn: ({ amount, paidAmount }) => amount - paidAmount,
        header: "Owed",
      },
      {
        id: "status",
        accessorKey: "status",
        header: "Status",
      },
      {
        id: "paymentDate",
        accessorFn: ({ paymentDate }) => (
          <>
            {formatDate(paymentDate)}
            <br />
            {moment(paymentDate).fromNow()}
          </>
        ),
        header: "payment Date",
      },
      {
        id: "method",
        accessorKey: "method",
        header: "Method",
      },
      {
        id: "receiptNumber",
        accessorKey: "receiptNumber",
        header: "Receipt Number",
      },
      {
        id: "additionalDetails.note",
        accessorKey: "additionalDetails.note",
        header: "Additional Details",
      },
      {
        id: "user",
        accessorFn: (row) => row.user && getUser[row.user],
        header: "Employee",
      },
      {
        id: "currency",
        accessorKey: "currency",
        header: "Currency",
      },
      {
        id: "description",
        accessorKey: "description",
        header: "Description",
      },
    ],
    [getStudent, getUser]
  );
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportRows = (rows: MRT_Row<Invoice>[]) => {
    let rowData = rows.map((row) => row.original);
    rowData = rowData.map((data) => ({
      ...data,
      user: getUser[data.user!],
      student: getStudent[data.student!],
      additionalDetails: JSON.stringify(data.additionalDetails),
    })) as any;
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    let rowData = rows.map((data) => ({
      ...data,
      user: getUser[data.user!],
      student: getStudent[data.student!],
      additionalDetails: JSON.stringify(data.additionalDetails),
    })) as any;
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    enableSelectAll: true,
    enableMultiRowSelection: true,
    data: dataTable, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    getRowId: (originalRow) => originalRow._id,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowSelection: true,
    enableRowActions: true,
    enableFullScreenToggle: false,
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 10, //adjust the size of the row select column
      },
    },
    initialState: {
      density: "compact",
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: { left: ["mrt-row-select", "mrt-row-actions"] },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: {
          xs: "10vh",
          sm: "45vh",
          md: "50vh",
          lg: "70vh",
          xl: "75vh",
        },
      },
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      ...actionMenu(
        acitonItems,
        row,
        {
          handleEdit,
          handleDelete,
          handlePreview,
          handlePaid,
          handleArchive,
          handleDownload,
          handleSendPdf,
        },
        closeMenu
      ),
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Box>
          <ButtonGroup
            sx={{
              flexWrap: "wrap",
            }}>
            <Button startIcon={<AddBox />} onClick={handleCreate}>
              Create
            </Button>
            <Button
              id="demo-positioned-button"
              aria-controls={
                Boolean(exportMenuEl) ? "demo-positioned-menu" : undefined
              }
              aria-haspopup="true"
              startIcon={<FileDownload />}
              aria-expanded={Boolean(exportMenuEl) ? "true" : undefined}
              onClick={(event: any) => {
                setExportMenuEl(event.target);
              }}>
              Export
            </Button>
          </ButtonGroup>
        </Box>
        <Select sx={{ m: 1 }} size="small" value={filter} onChange={filterData}>
          <MenuItem value={"all"}>All Data</MenuItem>
          <MenuItem value={"today"}>Today</MenuItem>
          <MenuItem value={"yesterday"}>Yesterday</MenuItem>
          <MenuItem value={"lastweek"}>Last Week</MenuItem>
          <MenuItem value={"lastmonth"}>Last Month</MenuItem>
          <MenuItem value={"archived"}>Archived</MenuItem>
        </Select>
        <RangeSelectorDatetime onRangeChange={handleRangeChage} />
      </Stack>
    ),
  });
  const handleRangeChage = (range: {
    start: Moment | null;
    end: Moment | null;
  }) => {
    const filtered = rows.filter((item) => {
      const created = moment(item.createdAt);
      return created.isBetween(
        moment(range.start),
        moment(range.end),
        "day",
        "[]"
      );
    });
    setDataTable(filtered);
  };
  const handleCreate = () => {
    setEdit(false);
    setSelectedRow(null);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setEdit(false);
    setSelectedRow(null);
  };
  const handleCloseCal = () => {
    setCalOpenModal(false);
  };

  const handleSubmitForm = (invoice: Invoice) => {
    for (const key in invoice) {
      if (invoice[key] === "" || invoice[key] === null) {
        delete invoice[key];
      }
      if (invoice[key] === "N/A") {
        invoice[key] = null;
      }
    }
    if (edit && selectedRow) {
      updateInvoicen(invoice)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    } else {
      createInvoice(invoice)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    }
  };

  const handleSendPdf = async (row: MRT_Row<Invoice>) => {
    const pdfFeilds = {
      student: getStudent[row.original?.student!],
      refNo: row.original.refNo,
      createdAt: formatDate(row.original.createdAt),
      service: row.original.service,
      quantity: 1,
      amount: row.original.amount,
      total: row.original.amount,
      subTotal: row.original.amount,
      grandTotal: row.original.amount,
      tax: 0,
      currancy: row.original.currency,
    };
    const pdfUrl = await fillPdfForm(InvoicePDF, pdfFeilds);
    const user = users.find((user) => user._id === row.original.user);
    const student = students.find(
      (student) => student._id === row.original.student
    );
    const emailBody: Email = {
      message: {
        from: `${user?.username} <${user?.email}>`,
        to: `${student?.fullName} <${student?.email}>`,
        text: `Inovice For ${row.original.service} | ${row.original.refNo}`,
        subject: `Inovice For ${row.original.service}`,
        attachments: [
          {
            path: pdfUrl,
          },
        ],
      },
      createdBy: row.original.user,
      status: "send",
    };
    await sendEmail(emailBody);
  };
  const handleDownload = async (row: MRT_Row<Invoice>) => {
    const pdfFeilds = {
      student: getStudent[row.original?.student!],
      refNo: row.original.refNo,
      createdAt: formatDate(row.original.createdAt),
      service: row.original.service,
      quantity: 1,
      amount: row.original.amount,
      total: row.original.amount,
      subTotal: row.original.amount,
      grandTotal: row.original.amount,
      tax: 0,
      currancy: row.original.currency,
    };
    const pdfUrl = await fillPdfForm(InvoicePDF, pdfFeilds);
    const pdfDoc = await PDFDocument.load(pdfUrl);
    const link = document.createElement("a");
    const url = URL.createObjectURL(
      new Blob([await pdfDoc.save()], { type: "application/pdf" }) as any
    );
    link.href = url;
    link.setAttribute("download", pdfData.refNo);
    link.click();
    link.remove();
  };
  const handleDelete = async (row: MRT_Row<Invoice>) => {
    try {
      if (
        window.confirm(`Are you sure you want to delete ${row?.original.refNo}`)
      ) {
        await deleteInvoice(row.id);
        setSelectedRow(null);
      }
      return row;
    } catch (error) {
      console.log(error);
    }
  };
  const handlePaid = (row: MRT_Row<Invoice>) => {
    const invoice = {
      ...row.original,
      status: "Paid",
    };

    updateInvoicen(invoice)
      .then((data) => {})
      .catch((err: Error) => {
        console.log(err);
      });
  };
  const handleArchive = (row: MRT_Row<Invoice>) => {
    const invoice = {
      ...row.original,
      status: "Archived",
    };

    updateInvoicen(invoice)
      .then((data) => {})
      .catch((err: Error) => {
        console.log(err);
      });
  };
  const handleEdit = (row: MRT_Row<Invoice>) => {
    setSelectedRow(row.original);
    setOpenModal(true);
    setEdit(true);
  };
  const handlePreview = (row: MRT_Row<Invoice>) => {
    const pdfFeilds = {
      student: getStudent[row.original?.student!],
      refNo: row.original.refNo,
      createdAt: formatDate(row.original.createdAt),
      service: row.original.service,
      quantity: 1,
      amount: row.original.amount,
      total: row.original.amount,
      subTotal: row.original.amount,
      grandTotal: row.original.amount,
      tax: 0,
      currancy: row.original.currency,
    };
    setPdfData(pdfFeilds);
    setCalOpenModal(true);
  };
  const filterData = (e: SelectChangeEvent) => {
    const value = e.target.value;
    let refDay = moment();
    let filtered = rows.filter((item) => {
      const created = moment(item.createdAt);
      return created.isSame(refDay, "day");
    });

    switch (value) {
      case "all":
        setDataTable(rows);
        break;
      case "today":
        refDay = moment();
        filtered = rows.filter((item) => {
          const created = moment(item.createdAt);
          return created.isSame(refDay, "day");
        });
        setDataTable(filtered);
        break;
      case "yesterday":
        refDay = moment().subtract(1, "day");
        filtered = rows.filter((item) => {
          const created = moment(item.createdAt);
          return created.isSame(refDay, "day");
        });
        setDataTable(filtered);
        break;
      case "lastweek":
        refDay = moment().subtract(1, "week");
        filtered = rows.filter((item) => {
          const created = moment(item.createdAt);
          return created.isBetween(refDay, moment(), "day");
        });
        setDataTable(filtered);
        break;
      case "lastmonth":
        refDay = moment().subtract(1, "month");
        filtered = rows.filter((item) => {
          const created = moment(item.createdAt);
          return created.isBetween(refDay, moment(), "day");
        });
        setDataTable(filtered);
        break;
      case "archived":
        filtered = rows.filter((item) => item.status === "Archived");
        setDataTable(filtered);
        break;
      default:
        setDataTable(rows);
        break;
    }
    setFilter(value);
  };
  return (
    <>
      <Menu
        anchorEl={exportMenuEl}
        open={Boolean(exportMenuEl)}
        onClose={() => {
          setExportMenuEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        {exportMenuItems<Invoice>(exportActions, table, {
          handleExportData,
          handleExportRows,
        })}
      </Menu>
      <Grid
        container
        sx={{
          flexDirection: "column",
          gap: 2,
          height: "100%",
          flexWrap: "nowrap",
        }}>
        {/* <Grid item sm={12}> */}

        {openModal && (
          <InvoicesForm
            submitHanlder={handleSubmitForm}
            edit={!!selectedRow}
            open={openModal}
            handleClose={handleClose}
            selectedInvoice={selectedRow}
            users={users}
            students={students}
          />
        )}
        {openCalModal && (
          <InvoiceViwer
            open={openCalModal}
            handleClose={handleCloseCal}
            pdfData={pdfData}
          />
        )}

        {/* </Grid> */}

        {/* <Paper sx={{ width: "100%", height: "100%", overflow: "scroll" }}> */}
        <MaterialReactTable table={table} />
        {/* </Paper> */}
      </Grid>
    </>
  );
}

export default InvoicesTable;
