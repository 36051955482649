import { Box } from '@mui/material'
import Loader from '../../components/common/Loader'
import TransactionsTable from '../../components/Transactions/Table'
import { useGetTransactionsQuery } from '../../services/tansactions.service'
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../../services/users.service';
import { useGetStudentsQuery } from '../../services/students.service';

function TransactionsPage() {
    const navigator = useNavigate()
    const { data, error, isLoading } = useGetTransactionsQuery('Transactions');
    const { data:students, error:studentError, isLoading:isLoadingStudents } = useGetStudentsQuery('Students');
    const { data:users, error:usersError, isLoading:isLoadingUsers } = useGetUsersQuery('Users');
    const isLoadingData=isLoadingStudents||isLoading||isLoadingUsers

    if(error||studentError||usersError){
        navigator('/')
    }


    if(error){
        navigator('/')
    }  
    if(isLoading){
        return <Loader/>
    }
  return (   
   <Box height={'100%'} width={'100%'}>
   {!isLoadingData&&<TransactionsTable students={students!} users={users!} rows={data!}/>}
   </Box>
  )
}

export default TransactionsPage

