import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetStudentsQuery,
  useUpdateStudentMutation,
} from "../../services/students.service";
import { Student } from "../../types/students";
import { Document, Note, Service } from "../../types/additionalData";
import { formatDate } from "../../util/helper";
import { useGetUsersQuery } from "../../services/users.service";
import Documents from "../features/Documents";
import Services from "../features/Services";
import Loader from "../common/Loader";
import Notes from "../features/Notes";
import StudentAdditionalInfo from "./StudentAdditionalInfo";
import TransactionsTable from "../Transactions/Table";
import { useGetTransactionsQuery } from "../../services/tansactions.service";
import { Transaction } from "../../types/transactions";
import InvoicesTable from "../Invoices/Table";
import { Invoice } from "../../types/invoices";
import { useGetInvoicessQuery } from "../../services/invoices.service";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}
function a11yProps(index: string) {
  return {
    id: `simple-tab-${index}`,
    value: index,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StudentBadges = ({
  student,
  handleOpenDetials,
}: {
  student: Student;
  handleOpenDetials: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const stats = {
    notes: student.additionalData.notes.length,
    services: student.additionalData.services.length,
    documents: student.additionalData.documents.length,
  };
  return (
    <div>
      <Button
        id="add-button"
        startIcon={<Add />}
        variant="contained"
        aria-controls={open ? "add-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}>
        Add
      </Button>
      <Menu
        id="add-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "add-button",
        }}>
        <MenuItem onClick={() => handleOpenDetials(student, "notes")}>
          Note
        </MenuItem>
        <MenuItem onClick={() => handleOpenDetials(student, "services")}>
          Service
        </MenuItem>
        <MenuItem onClick={() => handleOpenDetials(student, "documents")}>
          Document
        </MenuItem>
      </Menu>
    </div>
  );
};
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      //   style={{
      //     width: "100%",
      //     height: "100%",
      //     overflow: "scroll",
      //   }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function StudentDetails() {
  const { data: students } = useGetStudentsQuery("Students");
  const { data: users } = useGetUsersQuery("users");
  const { data: transactions } = useGetTransactionsQuery("Transactions");
  const { data: invoices } = useGetInvoicessQuery("Invoices");
  const [updateStudent] = useUpdateStudentMutation();
  const [student, setStudent] = useState<Student>();
  const [employee, setEmployee] = useState<string>();
  const [studentTransactions, setStudentTransactions] =
    useState<Transaction[]>();
  const [studentInvoices, setStudentInvoices] = useState<Invoice[]>();
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [details, setDetails] = useState<{
    type: string;
    student: Student;
  } | null>(null);
  const role = useSelector((state: RootState) => state.user.role);
  const permissions = useSelector((state: RootState) => state.user.permissions);
  const allowedResources = permissions.map((res) => {
    if (res.actions.includes("READ")) return res.resource;
  });
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log(newValue);
    setSelectedTab(newValue);
  };
  const { id } = useParams();

  useEffect(() => {
    if (students && users) {
      const foundStudent = students?.find((s) => s._id === id)!;
      const employeeName = users.find(
        (user) => user._id === foundStudent.employee
      )?.username!;
      const filteredTransacitons = transactions?.filter(
        (t) => t.student === foundStudent._id
      );
      const filteredInvoices = invoices?.filter(
        (t) => t.student === foundStudent._id
      );
      setStudentTransactions(filteredTransacitons);
      setEmployee(employeeName);
      setStudent(foundStudent);
      setStudentInvoices(filteredInvoices);
    }
    return () => {};
  }, [id, students, users, transactions, invoices]);

  if (!student) return <Loader />;
  const handleDocumentsUpdate = (documents: Document[]) => {
    const newStudent = {
      ...student,
      additionalData: {
        ...student.additionalData,
        documents: documents,
      },
    };
    updateStudent(newStudent)
      .then((data) => {})
      .catch((err: Error) => {
        console.log(err);
      });
  };
  const handleServicesUpdate = (services: Service[]) => {
    const newStudent = {
      ...student,
      additionalData: {
        ...student.additionalData,
        services: services,
      },
    };
    updateStudent(newStudent)
      .then((data) => {})
      .catch((err: Error) => {
        console.log(err);
      });
  };
  const handleNotesUpdate = (notes: Note[]) => {
    const newStudent = {
      ...student,
      additionalData: {
        ...student.additionalData,
        notes: notes,
      },
    };
    updateStudent(newStudent)
      .then((data) => {})
      .catch((err: Error) => {
        console.log(err);
      });
  };
  const handleOpenDetials = (student: Student, type: string) => {
    setDetails({ type: type, student });
  };
  const handleCloseDetials = () => {
    setDetails(null);
  };
  return (
    <>
      {details && (
        <StudentAdditionalInfo
          open={!!details}
          handleClose={handleCloseDetials}
          details={details}
        />
      )}
      <Container
        sx={{
          bgcolor: "Background",
          height: "100%",
          boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
          borderRadius: "8px",

          overflow: "auto",
        }}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                mt: 2,
                // boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
              }}>
              {/* <CardMedia
                sx={{ maxWidth: "120px" }}
                component="img"
                height="100%"
                placeholder=""
                width="50px"
              /> */}
              <CardContent sx={{ flex: "1" }}>
                <Grid container>
                  <Grid item>
                    <Typography gutterBottom variant="h5" component="div">
                      {student?.fullName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {student?.email}
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="body2" color="text.primary">
                        Ref No: {student?.refNo}
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        Passport No: {student?.passport?.number}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item display={"flex"} alignItems={"flex-end"}>
                    <Box mt={2}></Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item width={"100%"} xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="basic tabs example">
                {[
                  "Profile",
                  "Documents",
                  "Services",
                  "Notes",
                  "Transactions",
                  "Invoices",
                ]
                  .filter(
                    (item) =>
                      allowedResources.includes(item.toLocaleLowerCase()) ||
                      ["Profile", "Documents", "Services", "Notes"].includes(
                        item
                      )
                  )
                  .map((tab) => (
                    <Tab label={tab} {...a11yProps(tab)} />
                  ))}
                <Box marginLeft={"auto"} my={1}>
                  <StudentBadges
                    student={student}
                    handleOpenDetials={handleOpenDetials}
                  />
                </Box>
              </Tabs>
            </Box>
            <CustomTabPanel value={selectedTab} index={"Profile"}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <Typography variant="body1">Full Name</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {student?.fullName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Arabic Name</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {student?.arabicName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Phone Number</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {student?.phone}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Address</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {student?.address}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Passport</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {student?.passport?.number}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Nationality</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {student?.nationality}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Issue Date</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatDate(student?.passport?.issueDate)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Expiry Date</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatDate(student?.passport?.expiryDate)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Birth Date</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatDate(student?.passport?.birthDate)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Handled by</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {" "}
                    {employee}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Added At</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatDate(student?.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={"Documents"}>
              <Documents
                documents={student?.additionalData.documents!}
                onUpdate={handleDocumentsUpdate}
              />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={"Services"}>
              <Services
                services={student?.additionalData.services!}
                onUpdate={handleServicesUpdate}
              />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={"Notes"}>
              <Notes
                notes={student?.additionalData.notes!}
                onUpdate={handleNotesUpdate}
              />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={"Transactions"}>
              <TransactionsTable
                students={[student]!}
                users={users!}
                rows={studentTransactions!}
              />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={"Invoices"}>
              <InvoicesTable
                students={[student]!}
                users={users!}
                rows={studentInvoices!}
              />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default StudentDetails;
