import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, styled
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Notes from "../features/Notes";
import Services from "../features/Services";
import Documents from "../features/Documents";
import { Student } from "../../types/students";
import { Document, Note, Service } from "../../types/additionalData";
import { useUpdateStudentMutation } from "../../services/students.service";
import { LIST_OF_DOCUMENTS, SERVICES_LIST } from "../../util/constants";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "100%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function StudentAdditionalInfo({
  details,
  open,
  handleClose,
}: {
  details: { type: string; student: Student };
  open: boolean;
  handleClose: () => void;
}) {
  const student = details.student;
  const [notes, setNotes] = useState([...student.additionalData.notes]);
  const [documents, setDocuments] = useState([
    ...student.additionalData.documents,
  ]);
  const [services, setServices] = useState([
    ...student.additionalData.services,
  ]);

  useEffect(() => {
    if (documents.length === 0) {
      setDocuments(LIST_OF_DOCUMENTS);
    }
    if (services.length === 0) {
      setServices(SERVICES_LIST);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  const [updateStudent] = useUpdateStudentMutation();

  const handleNotesUpdate = (notes: Note[]) => {
    const newStudent = {
      ...student,
      additionalData: {
        ...student.additionalData,
        notes: notes,
      },
    };
    updateStudent(newStudent)
      .then((data) => {
        setNotes(notes);
      })
      .catch((err: Error) => {
        console.log(err);
      });
  };
  const handleDocumentsUpdate = (documents: Document[]) => {
    const newStudent = {
      ...student,
      additionalData: {
        ...student.additionalData,
        documents: documents,
      },
    };
    updateStudent(newStudent)
      .then((data) => {
        setDocuments(documents);
      })
      .catch((err: Error) => {
        console.log(err);
      });
  };
  const handleServicesUpdate = (services: Service[]) => {
    const newStudent = {
      ...student,
      additionalData: {
        ...student.additionalData,
        services: services,
      },
    };
    updateStudent(newStudent)
      .then((data) => {
        setServices(services);
      })
      .catch((err: Error) => {
        console.log(err);
      });
  };

  const content: { [key: string]: ReactNode } = {
    notes: <Notes notes={notes} onUpdate={handleNotesUpdate} />,
    services: <Services services={services} onUpdate={handleServicesUpdate} />,
    documents: (
      <Documents documents={documents} onUpdate={handleDocumentsUpdate} />
    ),
  };
  return (
    <StyledDialog open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textTransform: "capitalize" }}
        id="customized-dialog-title">
        {details.type}
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{content[details.type]}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default StudentAdditionalInfo;
