import {
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { User } from "../../types/users";
import FormBuilder from "../controls/FormBuilder";
import { Student } from "../../types/students";
import { Transaction } from "../../types/transactions";
import moment from "moment";
  const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      width: "80%",
      boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
      borderRadius: "8px",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  
  const initialTransactionData: Transaction = {
    transactionType: "income",
    amount: 0,
    description: "",
    currency: "RM",
    sourceAccount: 0,
    receptionAccount: 0,
    method: "deposit",
    receiptNumber: 0,
    invoice: 0,
    category: "",
    service: "",
    approver: "",
    refNo: "",
    transactionDate: moment(),
    _id: "",
  };
  function TransactionsForm({
    selectedTransaction,
    users,
    handleClose,
    open,
    edit,
    students,
    submitHanlder,
  }: {
    selectedTransaction: Transaction | null;
    users: User[];
    handleClose: any;
    open: boolean;
    edit: boolean;
    students: Student[];
    submitHanlder: any;
  }) {
    useLayoutEffect(() => {
      if (selectedTransaction) setTransactionData(selectedTransaction);
      return () => {
        setTransactionData(initialTransactionData);
      };
    }, [selectedTransaction]);
    const [transactionData, setTransactionData] = useState<Transaction>(
      initialTransactionData
    );
    const controls = useMemo(
      () => [
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true },
            otherProps: { fullWidth: true },
            id: "category",
            name: "category",
            label: "Category",
          },
        },
        {
          type: "select",
          control: {
            otherProps: { fullWidth: true },
            additional: { fullWidth: true, required: false },
            id: "student",
            name: "student",
            label: "Student",
            options: [
              ...students.map((student) => ({
                label: student.fullName,
                value: student._id,
              })),
            ],
          },
        },
        {
          type: "select",
          control: {
            otherProps: { fullWidth: true },
            additional: { fullWidth: true, required: false },
            id: "user",
            name: "user",
            label: "Employee",
            options: [
              ...users.map((user) => ({
                label: user.username,
                value: user._id,
              })),
            ],
          },
        },
        {
          type: "select",
          control: {
            otherProps: { fullWidth: true },
            additional: { fullWidth: true, required: true },
            id: "transactionType",
            name: "transactionType",
            label: "Transaction Type",
            options: [
              {
                label: "Income",
                value: "income",
              },
              {
                label: "Outcome",
                value: "outcome",
              },
            ],
          },
        },
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true, type: "number" },
            otherProps: { fullWidth: true },
            id: "amount",
            name: "amount",
            label: "Amount",
          },
        },
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true },
            otherProps: { fullWidth: true },
            id: "currency",
            name: "currency",
            label: "Currency",
          },
        },
        {
          type: "input",
          control: {
            otherProps: { fullWidth: true },
            additional: { fullWidth: true, required: false },
            id: "sourceAccount",
            name: "sourceAccount",
            label: "Source Account",
          },
        },
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true },
            otherProps: { fullWidth: true },
            id: "receptionAccount",
            name: "receptionAccount",
            label: "Reception Account",
          },
        },
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true },
            otherProps: { fullWidth: true },
            id: "method",
            name: "method",
            label: "Method",
          },
        },
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true },
            otherProps: { fullWidth: true },
            id: "receiptNumber",
            name: "receiptNumber",
            label: "Receipt Number",
          },
        },
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true },
            otherProps: { fullWidth: true },
            id: "invoice",
            name: "invoice",
            label: "Invoice",
          },
        },
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true },
            otherProps: { fullWidth: true },
            id: "service",
            name: "service",
            label: "Service",
          },
        },
        {
          type: "input",
          control: {
            additional: { fullWidth: true, required: true },
            otherProps: { fullWidth: true },
            id: "additionalDetails.note",
            name: "additionalDetails.note",
            label: "Additional Details",
          },
        },
        {
          type: "select",
          control: {
            otherProps: { fullWidth: true },
            additional: { fullWidth: true, required: false },
            id: "approver",
            name: "approver",
            label: "Approved By",
            options: [
              ...users
                .filter((u) => u.role === "admin")
                .map((user) => ({
                  label: user.username,
                  value: user._id,
                })),
            ],
          },
        },

        {
          type: "datetime",
          control: {
            timezone: "system",
            label: "Transaction Date",
            id: "transactionDate",
            name: "transactionDate",
            additional: { fullWidth: true, required: true },
          },
        },
      ],
      [students, users]
    );

    return (
      <StyledDialog open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {transactionData.refNo?.toUpperCase() || "Create New Transaction"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Formik
            enableReinitialize
            initialValues={transactionData}
            onSubmit={submitHanlder}>
            {({ handleSubmit }) => (
              <Box
                component={"form"}
                id="students"
                onSubmit={(e) => handleSubmit(e)}>
                <FormBuilder controls={controls} />
              </Box>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form="students">
            Submit
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }
  
  export default TransactionsForm;
  