import React from 'react'
import AuthLogin from '../../components/Auth/LoginForm'
import AuthWrapper from '../../components/Auth/AuthWrapper'

function LoginPage() {
  return (
    <AuthWrapper>
      <AuthLogin/>
    </AuthWrapper>
  )
}

export default LoginPage