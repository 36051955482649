import { DateTimePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import moment from 'moment';

function DateTimeInput({control,type,otherProps}:{control:any,type:string,otherProps:any}) {
  const formik = useFormikContext();
  const fieldProps = formik.getFieldProps(control.name);
const RenderDate=(type:string)=>{
    switch (type) {
        case 'datetime':
           return (
             <DateTimePicker
               label={control.label}
               {...control.additional}
               {...fieldProps}
               onChange={(value) => formik.setFieldValue(control.name, value)}
               value={moment(fieldProps.value)}
             />
           );
      default:
           return <DateTimePicker   
           label={control.label}
           {...control.additional}
           {...fieldProps}
           onChange={(value)=>formik.setFieldValue(control.name,value)}
           value={moment(fieldProps.value)}
       />
    }

}

  return RenderDate(type)
}

export default DateTimeInput