import { useMemo, useState } from 'react';
import { Transaction } from '../../types/transactions';
import { Box, Button, ButtonGroup, Divider, Grid, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { AddBox, Delete, Edit, FileDownload, Receipt } from "@mui/icons-material";
import {
  useCreateTransactionsMutation,
  useDeleteTransactionsMutation,
  useUpdateTransactionsMutation,
} from "../../services/tansactions.service";
import TransactionsForm from "./TransactionsForm";
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Student } from "../../types/students";
import { User } from "../../types/users";
import TransactionsCalculation from "./TransactionsCalculation";
import { download, generateCsv, mkConfig } from "export-to-csv";
import moment from "moment";
import { exportActions, exportMenuItems } from "../common/ExportMenu";
import { formatDate } from "../../util/helper";
const acitonItems = [
  {
    icon: <Edit color="primary" fontSize="small" />,
    action: "handleEdit",
    label: "Edit",
  },
  {
    icon: <Delete fontSize="small" color="error" />,
    action: "handleDelete",
    label: "Delete",
  },
];
const actionMenu = (
  items: any[],
  row: MRT_Row<Transaction>,
  actions: any,
  closeMenu: any
): React.ReactNode[] => {
  return items.map((item, idx) => (
    <MenuItem
      key={idx}
      onClick={() => {
        actions[item.action](row);
        closeMenu();
      }}
      sx={{ m: 0 }}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      {item.label}
    </MenuItem>
  ));
};
function TransactionsTable({
  rows,
  students,
  users,
}: {
  rows: Transaction[];
  students: Student[];
  users: User[];
}) {
  const [createTransaction] = useCreateTransactionsMutation();
  const [updateTransaction] = useUpdateTransactionsMutation();
  const [deleteTransaction] = useDeleteTransactionsMutation();
  const [selectedRow, setSelectedRow] = useState<Transaction | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCalModal, setCalOpenModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [exportMenuEl, setExportMenuEl] = useState<null | HTMLElement>(null);
  const getStudent = useMemo<{ [key: string]: string }>(
    () =>
      students.reduce(
        (prev, cur) => ({ ...prev, [cur._id]: cur.fullName }),
        {}
      ),
    [students]
  );
  const getUser = useMemo<{ [key: string]: string }>(
    () =>
      users.reduce((prev, cur) => ({ ...prev, [cur._id]: cur.username }), {}),
    [users]
  );

  const columns = useMemo<MRT_ColumnDef<Transaction>[]>(
    () => [
      {
        id: "transactionDate",
        accessorFn: ({ transactionDate }) => (
          <>
            {formatDate(transactionDate)}
            <br />
            {moment(transactionDate).fromNow()}
          </>
        ),
        header: "Transaction Date",
      },
      {
        id: "amount",
        accessorKey: "amount",
        header: "Amount",
      },
      {
        id: "transactionType",
        accessorKey: "transactionType",
        header: "Transaction Type",
      },
      {
        id: "currency",
        accessorKey: "currency",
        header: "Currency",
      },
      {
        id: "sourceAccount",
        accessorKey: "sourceAccount",
        header: "Source Account",
      },
      {
        id: "receptionAccount",
        accessorKey: "receptionAccount",
        header: "Reception Account",
      },
      {
        id: "method",
        accessorKey: "method",
        header: "Method",
      },
      {
        id: "student",
        accessorFn: (row) => row.student && getStudent[row.student],
        header: "Student",
      },
      {
        id: "service",
        accessorKey: "service",
        header: "Service",
      },
      {
        id: "category",
        accessorKey: "category",
        header: "Category",
      },
      {
        id: "invoice",
        accessorKey: "invoice",
        header: "Invoice",
      },
      {
        id: "receiptNumber",
        accessorKey: "receiptNumber",
        header: "Receipt Number",
      },
      {
        id: "refNo",
        accessorKey: "refNo",
        header: "Reference Number",
      },
      {
        id: "user",
        accessorFn: (row) => row.user && getUser[row.user],
        header: "Employee",
      },
      {
        id: "approver",
        accessorFn: (row) => row.user && getUser[row.user],
        header: "Approved By",
      },
      {
        id: "description",
        accessorKey: "description",
        header: "Description",
      },
      {
        id: "additionalDetails.note",
        accessorKey: "additionalDetails.note",
        header: "Additional Details",
      },
    ],
    [getStudent, getUser]
  );
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportRows = (rows: MRT_Row<Transaction>[]) => {
    let rowData = rows.map((row) => row.original);
    rowData = rowData.map((data) => ({
      ...data,
      user: getUser[data.user!],
      student: getStudent[data?.student!],
      additionalDetails: JSON.stringify(data.additionalDetails),
    })) as any;
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    let rowData = rows.map((data) => ({
      ...data,
      user: getUser[data.user!],
      student: getStudent[data?.student!],
      additionalDetails: JSON.stringify(data.additionalDetails),
    })) as any;
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    enableSelectAll: true,
    enableMultiRowSelection: true,
    data: rows, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    getRowId: (originalRow) => originalRow._id,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowSelection: true,
    enableRowActions: true,
    enableFullScreenToggle: false,
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 10, //adjust the size of the row select column
      },
    },
    initialState: {
      density: "compact",
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: { left: ["mrt-row-select", "mrt-row-actions"] },
    },

    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiTablePaperProps: {
      sx: {
        boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
      },
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: {
          xs: "10vh",
          sm: "45vh",
          md: "50vh",
          lg: "70vh",
          xl: "75vh",
        },
      },
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      ...actionMenu(
        acitonItems,
        row,
        {
          handleEdit,
          handleDelete,
        },
        closeMenu
      ),
    ],
    renderTopToolbarCustomActions: () => (
      <Box>
        <ButtonGroup
          sx={{
            flexWrap: "wrap",
          }}>
          <Button
            size="small"
            startIcon={<Receipt fontSize="small" />}
            color="info"
            onClick={() => setCalOpenModal(true)}>
            Details
          </Button>
          <Button startIcon={<AddBox />} onClick={handleCreate}>
            Create
          </Button>
          <Button
            id="demo-positioned-button"
            aria-controls={
              Boolean(exportMenuEl) ? "demo-positioned-menu" : undefined
            }
            aria-haspopup="true"
            startIcon={<FileDownload />}
            aria-expanded={Boolean(exportMenuEl) ? "true" : undefined}
            onClick={(event: any) => {
              setExportMenuEl(event.target);
            }}>
            Export
          </Button>
        </ButtonGroup>
      </Box>
    ),
  });

  const handleCreate = () => {
    setEdit(false);
    setSelectedRow(null);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setEdit(false);
    setSelectedRow(null);
  };
  const handleCloseCal = () => {
    setCalOpenModal(false);
  };

  const handleSubmitForm = (transcation: Transaction) => {
    for (const key in transcation) {
      if (transcation[key] === "" || transcation[key] === null) {
        delete transcation[key];
      }
      if (transcation[key] === "N/A") {
        transcation[key] = null;
      }
    }
    if (edit && selectedRow) {
      updateTransaction(transcation)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    } else {
      createTransaction(transcation)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    }
  };
  const handleDelete = async (row: MRT_Row<Transaction>) => {
    try {
      if (
        window.confirm(`Are you sure you want to delete ${row?.original.refNo}`)
      ) {
        await deleteTransaction(row.id);
        setSelectedRow(null);
      }
      return row;
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (row: MRT_Row<Transaction>) => {
    setSelectedRow(row.original);
    setOpenModal(true);
    setEdit(true);
  };
  return (
    <>
      <Menu
        anchorEl={exportMenuEl}
        open={Boolean(exportMenuEl)}
        onClose={() => {
          setExportMenuEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        {exportMenuItems<Transaction>(exportActions, table, {
          handleExportData,
          handleExportRows,
        })}
      </Menu>
      <Grid
        container
        sx={{
          flexDirection: "column",
          gap: 2,
          height: "100%",
          flexWrap: "nowrap",
        }}>
        {/* <Grid item sm={12}> */}

        {openModal && (
          <TransactionsForm
            submitHanlder={handleSubmitForm}
            edit={!!selectedRow}
            open={openModal}
            handleClose={handleClose}
            selectedTransaction={selectedRow}
            users={users}
            students={students}
          />
        )}
        {
          <TransactionsCalculation
            open={openCalModal}
            handleClose={handleCloseCal}
            users={users}
            students={students}
          />
        }

        {/* </Grid> */}

        {/* <Paper sx={{ width: "100%", height: "100%", overflow: "scroll" }}> */}
        <MaterialReactTable table={table} />
        {/* </Paper> */}
      </Grid>
    </>
  );
}

export default TransactionsTable