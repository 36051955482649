import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Notes from "../features/Notes";
import Services from "../features/Services";
import Documents from "../features/Documents";
import { Document, Note, Service } from "../../types/additionalData";
import { useUpdateSubmissionMutation } from "../../services/submissions.service";
import { Submission } from "../../types/submissions";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "100%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function SubmissionAdditionalInfo({
  details,
  open,
  handleClose,
}: {
  details: { type: string; submission: Submission };
  open: boolean;
  handleClose: () => void;
}) {
  const submission = details.submission;
  const [notes, setNotes] = useState([...submission.additionalData.notes])
  const [documents, setDocuments] = useState([...submission.additionalData.documents])
  const [services, setServices] = useState([...submission.additionalData.services])

  const [updateSubmission] = useUpdateSubmissionMutation();


  const handleNotesUpdate = (notes: Note[]) => {
    const newSubmission = {
        ...submission,
        additionalData: {
          ...submission.additionalData,
          notes: notes,
        },
      };
      updateSubmission(newSubmission)
        .then((data) => {
            setNotes(notes)
        })
        .catch((err: Error) => {
          console.log(err);
        });
   

  };
  const handleDocumentsUpdate = (documents: Document[]) => {
    const newSubmission = {
        ...submission,
        additionalData: {
          ...submission.additionalData,
          documents: documents,
        },
      };
      updateSubmission(newSubmission)
        .then((data) => {
            setDocuments(documents)
        })
        .catch((err: Error) => {
          console.log(err);
        });
  };
  const handleServicesUpdate = (services: Service[]) => {
    const newSubmission = {
        ...submission,
        additionalData: {
          ...submission.additionalData,
          services: services,
        },
      };
      updateSubmission(newSubmission)
        .then((data) => {
            setServices(services)
        })
        .catch((err: Error) => {
          console.log(err);
        });
  };

  const content: { [key: string]: ReactNode } = {
    notes: <Notes notes={notes} onUpdate={handleNotesUpdate} />,
    services: (
      <Services services={services} onUpdate={handleServicesUpdate} />
    ),
    documents: (
      <Documents documents={documents} onUpdate={handleDocumentsUpdate} />
    ),
  };
  return (
    <StyledDialog open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textTransform: "capitalize" }}
        id="customized-dialog-title">
        {details.type}
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{content[details.type]}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default SubmissionAdditionalInfo;
