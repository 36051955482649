import { Box } from "@mui/material";
import Loader from "../../components/common/Loader";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "../../services/users.service";
import { useGetStudentsQuery } from "../../services/students.service";
import { useGetInvoicessQuery } from "../../services/invoices.service";
import InvoicesTable from "../../components/Invoices/Table";

function InvoicesPage() {
  const navigator = useNavigate();
  const { data, error, isLoading } = useGetInvoicessQuery("Invoices");
  const {
    data: students,
    error: studentError,
    isLoading: isLoadingStudents,
  } = useGetStudentsQuery("Students");
  const {
    data: users,
    error: usersError,
    isLoading: isLoadingUsers,
  } = useGetUsersQuery("Users");
  const isLoadingData = isLoadingStudents || isLoading || isLoadingUsers;

  if (error || studentError || usersError) {
    navigator("/");
  }


  if (error) {
    navigator("/");
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Box height={"100%"} width={"100%"}>
      {!isLoadingData && (
        <InvoicesTable students={students!} users={users!} rows={data!} />
      )}
    </Box>
  );
}

export default InvoicesPage;
