import Toolbar from '@mui/material/Toolbar';
import { Box, IconButton, InputBase, Menu, MenuItem, Typography, styled } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { AccountCircle } from "@mui/icons-material";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/slices/users.slice';
import { Link, Router, useLocation } from 'react-router-dom';
import { RootState } from '../../store';
const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "#fff",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    background: "#fff",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
function Header({
  handleSidebar,
  sidebar,
}: {
  handleSidebar: () => void;
  sidebar: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const username = useSelector((state: RootState) => state.user.username);
  const dispatch = useDispatch();
  const location = useLocation();
  const title = location.pathname.split("/")[1] || "Dashboard";
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar open={sidebar} elevation={0} position="fixed">
      <Toolbar>
        <IconButton
          onClick={handleSidebar}
          size="large"
          edge="start"
          aria-label="open drawer"
          sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            display: { xs: "none", sm: "block" },
            textTransform: "capitalize",
          }}>
          {title}
        </Typography>
        {/* <div>
        <SearchIcon />
      <InputBase
        sx={{color:'#ffff'}}
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div> */}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Typography
            variant="subtitle1"
            fontWeight="semibold"
            color="MenuText">
            {username || ""}
          </Typography>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            // aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleMenu}>
            <AccountCircle />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="show more"
            // aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMenu}>
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
            <Link
              to={"login"}
              style={{ textDecoration: "none", color: "unset" }}>
              <MenuItem>Change User</MenuItem>
            </Link>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
