import { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Block, Download, QueryStats } from "@mui/icons-material";
import { PDFDocument } from "pdf-lib";
import InvoicePDF from "../../assets/docs/invoice_form.pdf";
import { fillPdfForm } from "../../util/helper";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "80%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function InvoiceViwer({
  handleClose,
  open,
  pdfData,
}: {
  handleClose: any;
  open: boolean;
  pdfData: any;
}) {
  const [pdfUrl, setPdfUrl] = useState("");
  useLayoutEffect(() => {
    const loadPDFDate = async (pdfData: any, InvoicePDF: any) => {
      const pdfDataUri = await fillPdfForm(InvoicePDF, pdfData);
      setPdfUrl(pdfDataUri);
    };
    if (pdfData !== null && open) {
      loadPDFDate(pdfData, InvoicePDF);
    }
  }, [open, pdfData]);
  const handleDownload = async () => {
    const pdfDoc = await PDFDocument.load(pdfUrl);
    const link = document.createElement("a");
    const url = URL.createObjectURL(
      new Blob([await pdfDoc.save()], { type: "application/pdf" }) as any
    );
    link.href = url;
    link.setAttribute("download", pdfData.refNo);
    link.click();
    link.remove();
  };
  return (
    <StyledDialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Invoice Preview
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box
          sx={{
            height: "700px",
          }}>
          <iframe src={pdfUrl} title="invoice" height={"100%"} width={"100%"} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleDownload} endIcon={<Download />}>
          Download
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default InvoiceViwer;
