import { createSlice } from "@reduxjs/toolkit";
import { Task } from "../../types/tasks";

const initialState: Task = {
  name: "",
  description: "",
  status: "To Do",
  dueDate: "",
  urgency: "low",
  createdBy: "",
  assignedTo: "",
  _id: "",
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
// export const {} = tasksSlice.actions;

export default tasksSlice.reducer;
