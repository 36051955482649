import { FormEvent, useState } from "react";
import {
  Box,
  TextField,
  Stack,
  Divider,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AddRounded, Delete, More } from "@mui/icons-material";
import { Service } from "../../types/additionalData";
import AssistantIcon from "@mui/icons-material/Assistant";
import moment from "moment";
import { formatDate } from "../../util/helper";
function Services({
  onUpdate,
  services,
}: {
  onUpdate: (services: Service[]) => void;
  services: Service[];
}) {
  const [edit, setEdit] = useState(false);
  const [serviceToExpand, setServiceToExpand] = useState<number | null>(null);
  const handleShowDetails = (id: number) => {
    if (!serviceToExpand) setServiceToExpand(id);
    if (serviceToExpand === id) setServiceToExpand(null);
    if (serviceToExpand !== id) setServiceToExpand(id);
  };
  const handleAdd = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.target as HTMLFormElement);
    const service: Service = {
      name: Object.fromEntries(form)["name"] as string,
      details: Object.fromEntries(form)["details"] as string,
      available: Object.fromEntries(form)["available"] as any,
      createdAt: moment(),
    };
    setEdit(false);
    onUpdate([...services, service]);
  };
  const handleDelete = (id: number) => {
    const newServices = services.filter((service, idx) => idx !== id);
    onUpdate([...newServices]);
  };
  const updateAvailablity = (id: number) => {
    let updatedService = services.map((service, idx) => {
      if (idx === id) {
        return {
          ...service,
          available: !service.available,
          createdAt: moment(),
        };
      } else {
        return service;
      }
    });

    onUpdate([...updatedService]);
  };

  const handleDetails = (e: any) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (serviceToExpand !== null) {
      let updatedService = services.map((service, idx) => {
        if (idx === serviceToExpand) {
          return {
            ...service,
            details: e?.target["details"]?.value,
          };
        } else {
          return service;
        }
      });

      onUpdate([...updatedService]);
    }
  };
  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item sm={12}>
        {edit ? (
          <Box
            component={"form"}
            flexDirection={"row"}
            display={"flex"}
            onSubmit={handleAdd}
            py={1}
            px={1}>
            <Card
              sx={{
                minWidth: 275,

                boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
              }}>
              <CardContent
                sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
                <TextField
                  required
                  size="small"
                  variant="standard"
                  label="Name"
                  fullWidth
                  name="name"
                  type="text"
                />

                <TextField
                  required
                  rows={2}
                  multiline
                  label="Details"
                  fullWidth
                  name="details"
                  type="text"
                />
              </CardContent>
              <CardActions>
                <Button startIcon={<AddRounded />} type="submit">
                  Create
                </Button>
                <Checkbox name="available" sx={{ marginLeft: "auto" }} />
              </CardActions>
            </Card>
          </Box>
        ) : (
          <Button startIcon={<AssistantIcon />} onClick={() => setEdit(true)}>
            Add New Service
          </Button>
        )}
      </Grid>
      <Divider sx={{ m: 1 }} />
      <Grid item sm={12}>
        <Box height={"100%"} overflow={"scroll"}>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {services.map((item, idx) => (
              <Box key={idx}>
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  alignItems={"center"}
                  divider={<Divider orientation="vertical" flexItem />}>
                  <Box sx={{ width: "100%" }}>
                    <ListItem
                      sx={{
                        height: "40px",
                      }}>
                      <ListItemButton role={undefined} dense>
                        <ListItemIcon>
                          <Checkbox
                            onChange={(e) => updateAvailablity(idx)}
                            checked={item.available}
                            name="available"
                            size="small"
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          secondary={formatDate(item.createdAt)}
                          primary={`${item.name}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Box>
                  <Stack direction={"column"}>
                    <Button
                      size="small"
                      onClick={() => handleDelete(idx)}
                      color="error">
                      <Delete />
                    </Button>
                    <Button size="small" onClick={() => handleShowDetails(idx)}>
                      <More />
                    </Button>
                  </Stack>
                </Stack>
                {serviceToExpand === idx && (
                  <Grid
                    container
                    component={"form"}
                    alignItems={"center"}
                    onSubmit={handleDetails}>
                    <Grid item xs={10}>
                      <TextField
                        rows={2}
                        sx={{
                          maxWidth: "90%",
                          m: 2,
                        }}
                        multiline
                        defaultValue={item.details}
                        label="Details"
                        fullWidth
                        name="details"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button type="submit">Save</Button>
                    </Grid>
                  </Grid>
                )}
                <Divider variant="fullWidth" component="li" sx={{ m: 1 }} />
              </Box>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Services;
