import MinimalLayout from "../layout/MinimalLayout";
import LoginPage from "../pages/Auth/LoginPage";

const LoginRoutes={
    path:'/',
    element:<MinimalLayout/>,
    children:[
        {path:'/login',element:<LoginPage/>},
      ]

}

export default LoginRoutes