import  {
    useLayoutEffect,
    useMemo,
    useState,
  } from "react";
  import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import { Formik } from "formik";
  import { User } from "../../types/users";
  import FormBuilder from "../controls/FormBuilder";
import { Student, STUDENT_STATUS } from "../../types/students";
import { countries } from "countries-list";
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "80%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const initialStudentData: Student = {
  passport: {
    number: "",
    issueDate: new Date(),
    expiryDate: new Date(),
    birthDate: new Date(),
  },
  address: "",
  fullName: "New Student",
  email: "",
  employee: "",
  nationality: "",
  status: "",
  commission: 0,
  university: "",
  country: "SA",
  phone: "",
  degree: "",
  program: "",
  additionalData: { documents: [], services: [], notes: [] },
  _id: "",
  refNo: "",
};
function StudentForm({
  selectedStudent,
  users,
  handleClose,
  open,
  edit,
  submitHanlder,
}: {
  selectedStudent: Student | null;
  users: User[];
  handleClose: any;
  open: boolean;
  edit: boolean;
  submitHanlder: any;
}) {
  useLayoutEffect(() => {
    if (selectedStudent) setStudentData(selectedStudent);
    return () => {
      setStudentData(initialStudentData);
    };
  }, [selectedStudent]);
  const [studentData, setStudentData] = useState<Student>(initialStudentData);
  const controls = useMemo(
    () => [
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "fullName",
          name: "fullName",
          label: "Full Name",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "arabicName",
          name: "arabicName",
          label: "Arabic Name",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "email",
          name: "email",
          label: "Email",
        },
      },
      {
        type: "select",
        control: {
          otherProps: { fullWidth: true },
          additional: { fullWidth: true, required: false },
          id: "employee",
          name: "employee",
          label: "Assigned To",
          options: [
            ...users.map((user) => ({
              label: user.username,
              value: user._id,
            })),
          ],
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "nationality",
          name: "nationality",
          label: "Nationality",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "university",
          name: "university",
          label: "University",
        },
      },
      {
        type: "select",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "country",
          name: "country",
          label: "Country",
          options: Object.entries(countries).map((c) => ({
            value: c[0],
            label: c[1]?.name,
          })),
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "phone",
          name: "phone",
          label: "Phone",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "address",
          name: "address",
          label: "Address",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "degree",
          name: "degree",
          label: "Degree",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "program",
          name: "program",
          label: "Program",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "passport.number",
          name: "passport.number",
          label: "Passport",
        },
      },
      {
        type: "datetime",
        control: {
          timezone: "system",
          label: "Birth Date",
          id: "passport.birthDate",
          name: "passport.birthDate",
          additional: { fullWidth: true, required: true },
        },
      },
      {
        type: "datetime",
        control: {
          timezone: "system",
          label: "Issue Date",
          id: "passport.issueDate",
          name: "passport.issueDate",
          additional: { fullWidth: true, required: true },
        },
      },
      {
        type: "datetime",

        control: {
          timezone: "system",
          label: "Expiry Date",
          id: "passport.expiryDate",
          name: "passport.expiryDate",
          additional: { fullWidth: true, required: true },
        },
      },
      {
        type: "select",
        control: {
          otherProps: { fullWidth: true },

          additional: {
            sx: { textTransform: "capitalize" },
            required: true,
          },
          id: "status",
          name: "status",
          label: "Status",
          options: [
            ...Object.keys(STUDENT_STATUS).map((item) => ({
              label: item,
              value: item,
              additional: {
                sx: { textTransform: "capitalize" },
              },
            })),
          ],
        },
      },
      {
        type: "input",
        control: {
          additional: { type: "number", fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "commission",
          name: "commission",
          label: "Commission",
        },
      },
    ],
    [users]
  );

  return (
    <StyledDialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {studentData.fullName}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik
          enableReinitialize
          initialValues={studentData}
          onSubmit={submitHanlder}>
          {({ handleSubmit }) => (
            <Box
              component={"form"}
              id="students"
              onSubmit={(e) => handleSubmit(e)}>
              <FormBuilder controls={controls} />
            </Box>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form="students">
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
  
  export default StudentForm;
  