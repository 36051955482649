import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useFormikContext } from 'formik';

function RadioButton({control,otherProps}:{control:any,otherProps:any}) {
  const formik = useFormikContext();
  const fieldProps=formik.getFieldProps(control.name);
  return (
    <FormControl  {...otherProps}>
  <FormLabel id={control.id}>{control.label}</FormLabel>
  <RadioGroup
          {...control.additional}
      {...fieldProps}
  >
    {control.options.map((option:any)=>(
        <FormControlLabel key={option.value} value={option.value} {...option.additional} control={<Radio />} label={option.label} />
    ))
    }
  </RadioGroup>
</FormControl>
  )
}

export default RadioButton