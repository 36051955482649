import { AddRounded, CreateNewFolder, Delete, More } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { Document } from "../../types/additionalData";
import moment from "moment";
import { formatDate } from "../../util/helper";
// const styleSelect = {
//   textTransform: "capitalize",
//   ".MuiOutlinedInput-notchedOutline": { border: 0 },
//   "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
//     border: 0,
//   },
//   "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
//     border: 0,
//   },
// };
// Passport Photo
// Passport Copy
// English Language Proficiency
// High School Certificate
// High School  Transcript
// Diploma Certificate
// Diploma Transcript
// Bachelor Degree Certificate
// Bachelor Degree Transcript
// Master Degree Certificate
// Master Degree Transcript
// Research Plan
// Recommendation Letter 1
// Recommendation Letter 2
// Experience Letter

function Documents({
  onUpdate,
  documents,
}: {
  onUpdate: (documents: Document[]) => void;
  documents: Document[];
}) {
  const [edit, setEdit] = useState(false);
  const [docToExpand, setDocToExpand] = useState<number | null>(null);

  const handleAdd = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.target as HTMLFormElement);
    const document: Document = {
      type: Object.fromEntries(form)["type"] as string,
      parts: {
        arabic: {
          name: Object.fromEntries(form)["arabic.name"] as any,
          available: Object.fromEntries(form)["arabic.available"] as any,
          submissionDate: moment(),
        },
        english: {
          name: Object.fromEntries(form)["english.name"] as any,
          available: Object.fromEntries(form)["english.available"] as any,
          submissionDate: moment(),
        },
      },
      details: Object.fromEntries(form)["details"] as any,
    };
    setEdit(false);
    onUpdate([...documents, document]);
  };
  const handleDelete = (id: number) => {
    const newDocuments = documents.filter((doc, idx) => idx !== id);
    onUpdate(newDocuments);
  };
  const handleShowDetails = (id: number) => {
    if (!docToExpand) setDocToExpand(id);
    if (docToExpand === id) setDocToExpand(null);
    if (docToExpand !== id) setDocToExpand(id);
  };
  const handleUpdateStatus = (id: number, e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name.split(".")[0] as "arabic" | "english";
    const value = e.target.checked;
    const newDocs = documents.map((doc, idx) => {
      if (idx === id) {
        return {
          ...doc,
          parts: {
            ...doc.parts,
            [name]: {
              ...doc.parts[name],
              available: value,
              submissionDate: moment(),
            },
          },
        };
      } else {
        return doc;
      }
    });

    onUpdate([...newDocs]);
  };

  const handleDetails = (e: any) => {
    e?.stopPropagation();
    e?.preventDefault();

    if (docToExpand !== null) {
      const newDocs = documents.map((doc, idx) => {
        if (idx === docToExpand) {
          return {
            ...doc,
            details: e?.target["details"]?.value,
          };
        } else {
          return doc;
        }
      });
      onUpdate([...newDocs]);
    }
  };
  return (
    <Grid container>
      <Grid item sm={12}>
        {edit ? (
          <Box
            component={"form"}
            flexDirection={"row"}
            display={"flex"}
            onSubmit={handleAdd}
            py={1}
            px={1}>
            <Card
              sx={{
                minWidth: 275,
                boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
              }}>
              <CardContent
                sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
                <TextField
                  required
                  size="small"
                  variant="filled"
                  label="Type"
                  fullWidth
                  name="type"
                  type="text"
                />
                <Grid container justifyContent={"center"}>
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Checkbox name="arabic.available" value={false} />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      required
                      size="small"
                      variant="filled"
                      label="Arabic Version"
                      fullWidth
                      name="arabic.name"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent={"center"}>
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Checkbox name="english.available" value={false} />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      required
                      size="small"
                      variant="filled"
                      label="English Version"
                      fullWidth
                      name="english.name"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <TextField
                  required
                  rows={2}
                  multiline
                  label="Details"
                  fullWidth
                  name="details"
                  type="text"
                />
              </CardContent>
              <CardActions>
                <Button startIcon={<AddRounded />} type="submit">
                  Create
                </Button>
              </CardActions>
            </Card>
          </Box>
        ) : (
          <Button startIcon={<CreateNewFolder />} onClick={() => setEdit(true)}>
            Add New Document
          </Button>
        )}
      </Grid>
      <Grid item sm={12}>
        <Box height={"100%"}>
          <List
            sx={{ width: "100%", height: "100%", bgcolor: "background.paper" }}>
            {documents.map((item, idx) => (
              <Box key={idx}>
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  alignItems={"center"}
                  divider={<Divider orientation="vertical" flexItem />}>
                  <Box sx={{ width: "100%" }}>
                    <ListItem
                      sx={{
                        height: "40px",
                      }}>
                      <ListItemButton role={undefined} dense>
                        <ListItemIcon>
                          <Checkbox
                            onChange={(e) => handleUpdateStatus(idx, e)}
                            checked={item.parts.arabic.available}
                            name="arabic.available"
                            size="small"
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          secondary={formatDate(
                            item.parts.arabic.submissionDate
                          )}
                          primary={`${item.parts.arabic.name} - AR`}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      sx={{
                        height: "40px",
                      }}>
                      <ListItemButton role={undefined} dense>
                        <ListItemIcon>
                          <Checkbox
                            onChange={(e) => handleUpdateStatus(idx, e)}
                            checked={item.parts.english.available}
                            size="small"
                            edge="start"
                            name="english.available"
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          secondary={formatDate(
                            item.parts.english.submissionDate
                          )}
                          primary={`${item.parts.english.name} - EN`}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Box>
                  <Stack direction={"column"}>
                    <Button
                      size="small"
                      onClick={() => handleDelete(idx)}
                      color="error">
                      <Delete />
                    </Button>
                    <Button size="small" onClick={() => handleShowDetails(idx)}>
                      <More />
                    </Button>
                  </Stack>
                </Stack>
                {docToExpand === idx && (
                  <Grid
                    container
                    component={"form"}
                    alignItems={"center"}
                    onSubmit={handleDetails}>
                    <Grid item xs={10}>
                      <TextField
                        rows={2}
                        sx={{
                          maxWidth: "90%",
                          m: 2,
                        }}
                        multiline
                        defaultValue={item.details}
                        label="Details"
                        fullWidth
                        name="details"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button type="submit">Save</Button>
                    </Grid>
                  </Grid>
                )}
                <Divider variant="fullWidth" component="li" sx={{ m: 1 }} />
              </Box>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Documents;
