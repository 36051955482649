import moment from "moment";
import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

export const formatDate = (date: any) => {
  if (!date) return "N/A";
  return moment(date).format("DD/MM/yyyy hh:mm  A").toLocaleString();
};
export const fillPdfForm = async (InvoicePDF: any, pdfData: any) => {
  const buffer = await fetch(InvoicePDF).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(buffer);
  pdfDoc.registerFontkit(fontkit);
  const fontUrl = `${process.env.PUBLIC_URL}/fonts/Cairo-Regular.ttf`;
  const arabicFontBytes = await fetch(fontUrl).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res.arrayBuffer();
  });
  console.log(arabicFontBytes);
  // Register the fontkit instance
  const arabicFont = await pdfDoc.embedFont(arabicFontBytes);

  // Embed the Arabic font
  const form = pdfDoc.getForm();
  const fields = form.getFields();
  for (const field in fields) {
    const name = fields[field].getName();
    const formField = form.getTextField(name);
    formField.setText(`${pdfData[name]}`);
    const [w] = formField.acroField.getWidgets();

    w.getOrCreateAppearanceCharacteristics().setBorderColor([1, 1, 1]);
    formField.updateAppearances(arabicFont);
  }

  return await pdfDoc.saveAsBase64({ dataUri: true });
};