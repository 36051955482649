import { UploadFile } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, styled } from '@mui/material';
import { ChangeEvent, useState } from "react";
import { useCreateSubmissionBatchMutation } from "../../services/submissions.service";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      width: "80%",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  function csvToArr(stringValue:string) {
    // Add logic
    const requiredFeilds = [
      "passport.number",
      "fullName",
      "email",
      "nationality",
      "country",
      "phone",
      "degree",
      "program",
    ];
    const [keys, ...rest] = stringValue
    .replaceAll("\"","")
      .trim()
      .split("\n")
      .map((item) => item.split(','));
  
    const formedArr = rest.map((item) => {
      const object:any= {};
      keys.forEach((key, index) => {
        if(requiredFeilds.includes(key)){
            object[key] = item.at(index)
        }
    });
      return object;
    });
  
    return formedArr;
  }
function SubmissionsImport({handleClose,open}:{handleClose:any,open:boolean}) {
const [dataArray, setAataArray] = useState<any>([])
const[createSubmissionBatch]= useCreateSubmissionBatchMutation()
const handleReadFile=(e: ChangeEvent<HTMLInputElement>)=>{
const files=e.target.files?.[0];
if(files){
    const fileReader = new FileReader();
    fileReader.readAsText(files)
    fileReader.onload = function (e:ProgressEvent<FileReader>) {
        // Access to content with e.target.result
        const result=e.target?.result as string
        const data=csvToArr(result);
        setAataArray(data)
      };
}

}
const handleImport=()=>{
if(dataArray){
    createSubmissionBatch(dataArray)
}
}
  return (
    <StyledDialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Stack direction={"row"} alignItems={"center"}>
          Import Submissions
          <Button startIcon={<UploadFile />} component="label">
            Upload File
            <input type="file" hidden onChange={handleReadFile} />
          </Button>
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {dataArray && (
          <table
            style={{
              border: "1px solid black",
            }}>
            <thead
              style={{
                border: "1px solid black",
              }}>
              <tr
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
                key={"header"}>
                {dataArray[0] &&
                  Object.keys(dataArray[0]).map((key: any) => (
                    <th
                      style={{
                        border: "1px solid black",
                        borderCollapse: "collapse",
                      }}
                      key={key}>
                      {key}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {dataArray &&
                dataArray.map((item: any, idx: number) => (
                  <tr
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                    }}
                    key={idx}>
                    {item &&
                      Object.values(item).map((cell: any, idx: number) => {
                        return (
                          <td
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                            }}
                            key={idx}>
                            {cell}
                          </td>
                        );
                      })}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button type="button" onClick={handleImport}>
          Import
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default SubmissionsImport