import {
  Middleware,
  MiddlewareAPI,
  combineReducers,
  configureStore,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import tasksReducer from "./slices/tasks.slice";
import usersReducer from "./slices/users.slice";
import { tasksApi } from "../services/tasks.service";
import { usersApi } from "../services/users.service";
import { submissionsApi } from "../services/submissions.service";
import { studentsApi } from "../services/students.service";
import notificaionsReducer from "./slices/notification.slice";
import ToastCustom from "../hooks/ToastCustom";
import { transactionsApi } from "../services/tansactions.service";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { analyticsApi } from "../services/analytics.service";
import { emailsApi } from "../services/email.service";
import { invoicesApi } from "../services/invoices.service";
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      const errors =
        "data" in action.error
          ? (action.error.data as { message: string })?.message
          : action.payload.data?.message;
      if (typeof errors !== "object") {
        ToastCustom.error(errors || "Unknown Error");
      } else {
        errors.forEach((error: string) => {
          ToastCustom.error(error || "Unknown Error");
        });
      }
    }

    return next(action);
  };

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, usersReducer);
const rootReduer = combineReducers({
  user: persistedReducer,
  tasks: tasksReducer,
  notificaions: notificaionsReducer,
  [emailsApi.reducerPath]: emailsApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [submissionsApi.reducerPath]: submissionsApi.reducer,
  [studentsApi.reducerPath]: studentsApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
});
export const store = configureStore({
  reducer: rootReduer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      rtkQueryErrorLogger,
      emailsApi.middleware,
      tasksApi.middleware,
      usersApi.middleware,
      invoicesApi.middleware,
      submissionsApi.middleware,
      studentsApi.middleware,
      transactionsApi.middleware,
      analyticsApi.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
