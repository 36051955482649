import {
    useLayoutEffect,
    useMemo,
    useState,
  } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { User } from "../../types/users";
import FormBuilder from "../controls/FormBuilder";
import { SUBMISSION_STATUS, Submission } from "../../types/submissions";
import { countries } from "countries-list";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "80%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const initialSubmissionData: Submission = {
  passport: {
    number: "",
    issueDate: new Date(),
    expiryDate: new Date(),
    birthDate: new Date(),
  },
  fullName: "New Submission",
  email: "",
  employee: "",
  nationality: "",
  country: "SA",
  phone: "",
  degree: "",
  program: "",
  status: SUBMISSION_STATUS.initial,
  additionalData: { documents: [], services: [], notes: [] },
  _id: "",
  refNo: "",
};
function SubmissionForm({
  selectedSubmission,
  users,
  handleClose,
  open,
  edit,
  submitHanlder,
}: {
  selectedSubmission: Submission | null;
  users: User[];
  handleClose: any;
  open: boolean;
  edit: boolean;
  submitHanlder: any;
}) {
  const [shouldDisable, setShouldDisable] = useState<boolean>(true);
  const currentUser = useSelector((state: RootState) => state.user);
  const [submissionData, setSubmissionData] = useState<Submission>(
    initialSubmissionData
  );

  useLayoutEffect(() => {
    if (selectedSubmission) setSubmissionData(selectedSubmission);
    if (currentUser.role === "admin") {
      setShouldDisable(false);
    }
    if (!edit) {
      setShouldDisable(false);
    }
    return () => {
      setSubmissionData(initialSubmissionData);
    };
  }, [selectedSubmission, edit, currentUser]);
  const controls = useMemo(
    () => [
      {
        type: "select",
        control: {
          otherProps: { fullWidth: true },

          additional: {
            sx: { textTransform: "capitalize" },
            required: true,
          },
          id: "status",
          name: "status",
          label: "Status",
          options: [
            ...Object.keys(SUBMISSION_STATUS).map((item) => ({
              label: item,
              value: item,
              additional: {
                sx: { textTransform: "capitalize" },
              },
            })),
          ],
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "passport.number",
          name: "passport.number",
          label: "Passport",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "fullName",
          name: "fullName",
          label: "Full Name",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "email",
          name: "email",
          label: "Email",
        },
      },
      {
        type: "select",
        control: {
          otherProps: { fullWidth: true, disabled: shouldDisable },
          additional: { fullWidth: true, required: false },
          id: "employee",
          name: "employee",
          label: "Assigned To",
          options: [
            ...users.map((user) => ({
              label: user.username,
              value: user._id,
            })),
          ],
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "nationality",
          name: "nationality",
          label: "Nationality",
        },
      },
      {
        type: "select",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "country",
          name: "country",
          label: "Country",
          options: Object.entries(countries).map((c) => ({
            value: c[0],
            label: c[1]?.name,
          })),
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "phone",
          name: "phone",
          label: "Phone",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "degree",
          name: "degree",
          label: "Degree",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "program",
          name: "program",
          label: "Program",
        },
      },
      {
        type: "datetime",
        control: {
          timezone: "system",
          label: "Created At",
          id: "CreatedAt",
          name: "createdAt",
          additional: {
            fullWidth: true,
            required: true,
            disabled: shouldDisable,
          },
        },
      },
      {
        type: "input",
        control: {
          additional: { type: "number", fullWidth: true, required: true },
          otherProps: { fullWidth: true, disabled: shouldDisable },
          id: "commission",
          name: "commission",
          label: "Commission",
        },
      },
    ],
    [users, edit, shouldDisable]
  );

  return (
    <StyledDialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {submissionData.fullName}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik
          enableReinitialize
          initialValues={submissionData}
          onSubmit={submitHanlder}>
          {({ handleSubmit, resetForm }) => (
            <Box
              component={"form"}
              id="submissions"
              onSubmit={(e) => handleSubmit(e)}>
              <FormBuilder controls={controls} />
            </Box>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form="submissions">
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default SubmissionForm;
  