import { Card, CardContent, Chip, Stack, Typography } from "@mui/material";
import { TrendingDown ,TrendingFlat,TrendingUp} from "@mui/icons-material";
type ComponentProps={
  title:string;
  value:number;
  comment:string;
  percentage:number;
}
function AnalyticCard({title,value,comment,percentage}:ComponentProps) {
  const getTrendIcon=(percentage:number)=>{
    if(percentage===0) return <TrendingFlat/>;
    if(percentage>0) return <TrendingUp/>;
    if(percentage<0) return <TrendingDown/>
  }
  const getTrendColor=(percentage:number)=>{
    if(percentage===0) return 'primary';
    if(percentage>0) return 'success';
    if(percentage<0) return 'error'
  }
  return (
    <Card
      sx={{
        maxWidth: "100%",
        width: "100%",
        // minWidth: 250,
        borderRadius: "10px",
        // background:
        //   "linear-gradient(45deg,var(--cui-primary-start) 0%,var(--cui-primary-stop) 100%)",
        // bgcolor: "#5856d6",
        // color: "white",
        boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
        // "&:hover": {
        //   boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.15);",
        // },
      }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 14, color: "inherit" }}
          color="text.secondary"
          gutterBottom>
          {title}
        </Typography>
        <Stack direction={"column"} spacing={2} alignItems="flex-start">
          <Typography variant="h5" component="div" fontWeight={"semibold"}>
            {Intl.NumberFormat().format(value)}
          </Typography>
          <Chip
            label={`${percentage.toPrecision(4)}%`}
            icon={getTrendIcon(percentage)}
            size="small"
            color={getTrendColor(percentage)}
            sx={{ borderRadius: "5px" }}
          />
        </Stack>

        {/* <Typography variant="body2"  color="text.secondary" mt={1}>
    {percentage===0?'There is no change in the trend':comment}
    </Typography> */}
      </CardContent>
    </Card>
  );
}

export default AnalyticCard;
