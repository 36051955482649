export const LIST_OF_DOCUMENTS = [
  {
    type: "personal",
    parts: {
      arabic: {
        name: "Passport Photo",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Passport Photo",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "personal",
    parts: {
      arabic: {
        name: "Passport Copy",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Passport Copy",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "English Language Proficiency",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "English Language Proficiency",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "High School Certificate",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "High School Certificate",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "High School Transcript",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "High School Transcript",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "Diploma Certificate",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Diploma Certificate",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "Diploma Transcript",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Diploma Transcript",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "Bachelor Degree Certificate",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Bachelor Degree Certificate",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "Bachelor Degree Transcript",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Bachelor Degree Transcript",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "Master Degree Certificate",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Master Degree Certificate",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "Master Degree Transcript",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Master Degree Transcript",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "academic",
    parts: {
      arabic: {
        name: "Research Plan",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Research Plan",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "professional",
    parts: {
      arabic: {
        name: "Recommendation Letter 1",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Recommendation Letter 1",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "professional",
    parts: {
      arabic: {
        name: "Recommendation Letter 2",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Recommendation Letter 2",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
  {
    type: "professional",
    parts: {
      arabic: {
        name: "Experience Letter",
        available: false,
        submissionDate: null,
      },
      english: {
        name: "Experience Letter",
        available: false,
        submissionDate: null,
      },
    },
    details: "",
  },
];

// Offer Letter
// Visa Process
// Accommodation
// Welcoming on Arrival
export const SERVICES_LIST = [
  {
    name: "Offer Letter",
    details: "",
    available: false,
    createdAt: null,
  },
  {
    name: "Visa Process",
    details: "",
    available: false,
    createdAt: null,
  },
  {
    name: "Accommodation",
    details: "",
    available: false,
    createdAt: null,
  },
  {
    name: "Welcoming on Arrival",
    details: "",
    available: false,
    createdAt: null,
  },
];
