import { Analytics, CardPeriod, CardStats } from "../../types/analytics";
type Periods = "daily" | "monthly" | "weekly";
function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
  return k in x;
}
export const parseData = (analytics: Analytics, period: Periods) => {
  const cardsData = {
    students: {},
    submissions: {},
    transactions: {},
    tasks: {},
  } as CardStats;
  for (const key in analytics) {
    if (isKey(analytics, key)) {
      for (const period in analytics[key]["stats"]) {
        if (isKey(analytics[key]["stats"], period)) {
          const stats = analytics[key]["stats"][period] as any;
          if (!cardsData[key][period])
            cardsData[key][period] = {} as CardPeriod;
          if (!cardsData[key][period]["trend"])
            cardsData[key][period]["trend"] = {} as any;
          cardsData[key][period]["stats"] = stats?.toSorted(
            (a: any, b: any) => {
              switch (period) {
                case "daily":
                  if (a._id.year !== b._id.year) {
                    return a._id.year - b._id.year;
                  }
                  if (a._id.month !== b._id.month) {
                    return a._id.month - b._id.month;
                  }
                  return a._id.day - b._id.day;
                case "monthly":
                  if (a._id.year !== b._id.year) {
                    return a._id.year - b._id.year;
                  }
                  return a._id.month - b._id.month;
                case "weekly":
                  if (a._id.year !== b._id.year) {
                    return a._id.year - b._id.year;
                  }
                  return a._id.week - b._id.week;
                default:
                  return true;
              }
            }
          );
        }
      }
    }
  }
  return cardsData;
};
