import React, { FormEvent, useState } from "react";
import { Box, TextField,Stack, Divider, Button, Grid, Card, CardContent, CardActions, Typography } from "@mui/material";
import { AddRounded, Delete} from "@mui/icons-material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Note } from "../../types/additionalData";
import moment from "moment";
import { formatDate } from "../../util/helper";

function Notes({
  onUpdate,
  notes,
}: {
  onUpdate: (notes: Note[]) => void;
  notes: Note[];
}) {
  const [edit, setEdit] = useState(false);

  const handleAdd = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.target as HTMLFormElement);
    const note: Note = {
      title: Object.fromEntries(form)["title"] as string,
      note: Object.fromEntries(form)["note"] as string,
      createdAt: moment(),
    };
    setEdit(false);
    onUpdate([note, ...notes]);
  };
  const handleDelete = (id: number) => {
    const newNote = notes.filter((note, idx) => idx !== id);
    onUpdate([...newNote]);
  };
  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item sm={12}>
        {edit ? (
          <Box
            component={"form"}
            flexDirection={"row"}
            display={"flex"}
            onSubmit={handleAdd}
            py={1}
            px={1}>
            <Card
              sx={{
                minWidth: 275,
                boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
              }}>
              <CardContent
                sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
                <TextField
                  required
                  size="small"
                  variant="standard"
                  label="Title"
                  fullWidth
                  name="title"
                  type="text"
                />
                <TextField
                  required
                  rows={2}
                  multiline
                  label="Note"
                  fullWidth
                  name="note"
                  type="text"
                />
              </CardContent>
              <CardActions>
                <Button startIcon={<AddRounded />} type="submit">
                  Create
                </Button>
              </CardActions>
            </Card>
          </Box>
        ) : (
          <Button startIcon={<EditNoteIcon />} onClick={() => setEdit(true)}>
            Add New Note
          </Button>
        )}
      </Grid>
      <Divider sx={{ m: 1 }} />
      <Grid item sm={12}>
        <Box height={"100%"} overflow={"scroll"}>
          <Stack spacing={1} py={1} px={1}>
            {notes.map((item, idx) => (
              <Card sx={{ minWidth: 275 }} key={idx}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    variant="body2">
                    {formatDate(item.createdAt)}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2">{item.note}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    startIcon={<Delete color="error" />}
                    color="error"
                    onClick={() => handleDelete(idx)}>
                    Delete
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Notes;
