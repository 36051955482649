import React from 'react'
import { useGetSubmissionsQuery } from '../../services/submissions.service';
import { Box } from '@mui/material';
import Loader from '../../components/common/Loader';
import SubmissionsTable from '../../components/Submissions/Table';
import { useGetUsersQuery } from '../../services/users.service';
import { useNavigate } from 'react-router-dom';

function SubmissionsPage() {
    const navigator = useNavigate()
    const { data:submissions, error:submissionsError, isLoading:isLoadingSubmissions } = useGetSubmissionsQuery('Submissions');
    const { data:users, error:usersError, isLoading:isLoadingUsers } = useGetUsersQuery('Users');

    const isLoadingData=isLoadingSubmissions||isLoadingUsers

    if(usersError||submissionsError){
        navigator('/')
    }


    return (
    
        <Box height={'100%'} width={'100%'} >
        {isLoadingData?<Loader/>: <SubmissionsTable users={users!} rows={submissions as any}/>}
        </Box>
       )
}

export default SubmissionsPage