import {
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import {
  Box,
  Button, Checkbox, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField, Typography, styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  ACTIONS,
  ASSIGN_ROLES,
  Permission,
  ROLES,
  User,
} from "../../types/users";
import FormBuilder from "../controls/FormBuilder";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "80%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const initialPermission: Permission[] = [
  { resource: "transactions", actions: [] },
  { resource: "tasks", actions: [] },
  { resource: "submissions", actions: [] },
  { resource: "students", actions: [] },
  { resource: "analytics", actions: [] },
  { resource: "invoices", actions: [] },
];
const initialUserData: User = {
  username: "",
  role: "",
  email: "",
  tasks: [],
  submissions: [],
  transactions: [],
  assignRole: "auto",
  permissions: initialPermission,
  _id: "",
};
function UserForm({
  selectedUser,
  handleClose,
  open,
  edit,
  submitHanlder,
}: {
  selectedUser: User | null;
  handleClose: any;
  open: boolean;
  edit: boolean;
  submitHanlder: any;
}) {
  useLayoutEffect(() => {
    if (selectedUser) setUserData(selectedUser);
    if (selectedUser?.permissions) {
      const userPermissions = initialPermission.map((permission) => {
        const newPermission = selectedUser?.permissions.find(
          (p) => p.resource === permission.resource
        );
        return newPermission ? newPermission : permission;
      });
      setPermissions(userPermissions);
    }
    return () => {
      setUserData(initialUserData);
      setPermissions(initialPermission);
    };
  }, [selectedUser]);
  const [userData, setUserData] = useState<User>(initialUserData);
  const [permissions, setPermissions] =
    useState<Permission[]>(initialPermission);
  console.log("userData", userData);
  const handleChange = (event: any, index: number) => {
    const { name, value } = event.target;
    setPermissions((prevPermissions) => {
      const updatedPermissions = [...prevPermissions];
      updatedPermissions[index] = {
        ...updatedPermissions[index],
        [name || ""]: value,
      };
      return updatedPermissions;
    });
  };
  const handleSubmit = (e: any) => {
    const tempUser = { ...e, permissions: permissions };
    submitHanlder(tempUser);
  };
  const controls = useMemo(
    () => [
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "username",
          name: "username",
          label: "User Name",
        },
      },
      {
        type: "select",
        control: {
          otherProps: { fullWidth: true },
          additional: { fullWidth: true, required: false },
          id: "role",
          name: "role",
          label: "Role",
          options: [...ROLES.map((role) => ({ label: role, value: role }))],
        },
      },
      {
        type: "input",
        control: {
          additional: {
            fullWidth: true,
            required: true,
            type: "email",
          },
          otherProps: { fullWidth: true },
          id: "email",
          name: "email",
          label: "Email",
        },
      },
      {
        type: "select",
        control: {
          additional: {
            fullWidth: true,
            required: true,
            type: "string",
          },
          otherProps: { fullWidth: true },
          id: "assignRole",
          name: "assignRole",
          label: "Assign Role",
          options: [
            ...ASSIGN_ROLES.map((role) => ({ label: role, value: role })),
          ],
        },
      },
      {
        type: "input",
        control: {
          additional: {
            fullWidth: true,
            required: !edit,
            type: "password",
          },
          otherProps: { fullWidth: true },
          type: "password",
          id: "password",
          name: "password",
          label: "Password",
        },
      },
    ],
    [edit]
  );
  return (
    <StyledDialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {userData.username || "Create a new user"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik initialValues={userData} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <Box
              component={"form"}
              id="mytask"
              onSubmit={(e) => handleSubmit(e)}>
              <FormBuilder controls={controls} />
              <Grid>
                <Typography variant="h6" gutterBottom>
                  Edit Permissions
                </Typography>
                {permissions.map((permission, index) => (
                  <Grid container spacing={3} key={index} p={1}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>{permission.resource}</InputLabel>
                        <Select
                          name="actions"
                          multiple
                          variant="standard"
                          value={permission.actions}
                          onChange={(event) => handleChange(event, index)}
                          renderValue={(selected) =>
                            (selected as ACTIONS[]).join(", ")
                          }>
                          {Object.values(ACTIONS).map((action) => (
                            <MenuItem key={action} value={action}>
                              <Checkbox
                                checked={permission.actions.includes(action)}
                              />
                              <ListItemText primary={action} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form="mytask">
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default UserForm;
