import { Box, Card, CardContent, Chip, Divider, Stack, Typography } from '@mui/material'
import { useGetRecentTasksQuery } from '../../services/tasks.service';
import { CHIPS_COLOR } from '../../types/tasks';
import {blueGrey } from '@mui/material/colors';
import moment from 'moment';

function TasksCard() {
    const { data } = useGetRecentTasksQuery('Tasks');
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",

        borderRadius: "10px",
        // "&:hover": {
        //   boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.15);",
        // },
        boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
      }}>
      <Box p={1}>
        <Typography variant="h6" color={"black"}>
          Recent Tasks
        </Typography>
      </Box>
      <Divider />
      <CardContent sx={{ height: "75%", overflow: "scroll" }}>
        <Stack direction={"column"} divider={<Divider />}>
          {data?.map((task) => (
            <Stack
              justifyContent={"space-between"}
              key={task._id}
              direction={"row"}
              spacing={1}
              p={1}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {task.name}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {moment(task.dueDate).fromNow()}
              </Typography>
              <Chip
                sx={{ borderRadius: "8px", marginLeftt: "auto" }}
                size="small"
                label={task.status}
                color={CHIPS_COLOR[task.status]}
              />
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default TasksCard