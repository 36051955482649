import { Chip, Grid, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RangeSelectorDatetime from '../common/RangeSelectorDatetime'
import Chart from 'react-apexcharts'
import { useGetFilteredStudentsMutation, useGetFilteredSubmissionsMutation } from '../../services/analytics.service'
import moment, { Moment } from 'moment'
const chartStyle = {
  height: "100%",
  width: "100%",
  flexDirection: "column",
  bgcolor: "#ffffff",
  boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
  borderRadius: "8px",

  p: 2,
};
const dataBar={
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ]
    };
function StudentsSubmissionsChart() {
    const [students,setStudents]=useState<any>({});
    const [submissions,setSubmissions]=useState<any>({});
    const [pipeline, setPipeline] = useState({
        ranges:[{
            field: "createdAt",
          start: moment().subtract(1,'weeks'),
          end: moment() 
        },
    ],
    "dynamicFilters": [],
    "groupOptions": {
     
    }
    })
    const [chartData, setChartData] = useState<any>({
        categories:[],
        series:[]
    })
    // const  [dataSource, setDataSource] = useState<string[]>(['students']);
        const [getStudents]=useGetFilteredStudentsMutation();
       const [getSubmissions]=useGetFilteredSubmissionsMutation();
       const getFilteredStudents=async(pipeline:any)=>{
        return  await getStudents(pipeline).then((data:any)=>{
             const items:any[]=data?.data[0]?.item;
             const count=data?.data[0].count;
             const groupedValues=Object.groupBy(items,({createdAt}:any)=>createdAt.split('T')[0]);
             const keys=Object.keys(groupedValues)
             const categories=keys.sort((a,b)=>{
                 return new Date(a).valueOf()-new Date(b).valueOf()
             })
             return {categories,groupedValues,count}
             
             }).catch((err)=>{
                 console.log(err)
             })
     }
     const getFilteredSubmissions=async(pipeline:any)=>{
        return  await getSubmissions(pipeline).then((data:any)=>{
             const items:any[]=data?.data[0]?.item;
             const count=data?.data[0].count;
             const groupedValues=Object.groupBy(items,({createdAt}:any)=>createdAt.split('T')[0]);
             const keys=Object.keys(groupedValues)
             const categories=keys.sort((a,b)=>{
                 return new Date(a).valueOf()-new Date(b).valueOf()
             })
             return {categories,groupedValues,count}
             
             }).catch((err)=>{
                 console.log(err)
             })
     }

       const handleRangeChage=(range:{start:Moment|null,end:Moment|null})=>{
        setPipeline({
            ...pipeline,
            ranges:[{
                field: "createdAt",
                start:range.start?.toISOString() as any,
                end:range.end?.toISOString() as any ,
            }]
        })
        
        }
        useEffect(() => {
            const getStudentData=async()=>{
                const students=await getFilteredStudents(pipeline) as any;
                setStudents(students)
            }
            const getSubmissionsData=async()=>{
                const submissions=await getFilteredSubmissions(pipeline) as any;
                setSubmissions(submissions)
            }
            getStudentData()
            getSubmissionsData()
        }, [pipeline]);

    useEffect(() => {

    
            if(students?.categories&&submissions?.categories){
console.log('both')
                const categories=[...students.categories,...submissions.categories].sort((a,b)=>{
                    return new Date(a).valueOf()-new Date(b).valueOf()
                })
                let data = {
                  categories: Array.from(new Set(categories)),
                  series: [
                    {
                      name: "students",
                      data: categories.map(
                        (item: any) => students.groupedValues[item]?.length || 0
                      ),
                    },
                    {
                      name: "submissions",
                      data: categories.map(
                        (item: any) =>
                          submissions.groupedValues[item]?.length || 0
                      ),
                    },
                  ],
                };
                setChartData({...data})
    }
        
    
       
     
    }, [pipeline,students,submissions])
    

  return (
    <Grid item container flex={3} sx={chartStyle} flexDirection={'column'}
       >
        <Grid  item container gap={1} height={'30px'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
       <RangeSelectorDatetime onRangeChange={handleRangeChage}/>
       <Paper sx={{p:1}}>
    <Typography variant='subtitle1'>
    Total Student Count : {students?.count}
    </Typography>
  
  </Paper>
       <Paper sx={{p:1}}>
    <Typography variant='subtitle1'>
    Total Submissions Count : {submissions?.count}
    </Typography>
  
  </Paper>
       {/* <Stack sx={{marginRight:'auto'}} direction={'row'} spacing={0.5}>

{['students','submissions'].map((source:any)=>(
  <Chip sx={{borderRadius:'10px'}} key={source} label={source} component={'button'} onClick={()=>handleAddSource(source)} 
  color={dataSource.includes(source)?'primary':'default'}
  />
  ))}
  </Stack> */}
        </Grid>
        <Grid item height={'100%'} flex={100}>
        <Chart
              options={{...dataBar.options,xaxis:{
                categories:chartData.categories
              }}}
              
              series={chartData.series}
              type="bar"
              height="100%"
              />
              </Grid>
</Grid>
  )
}

export default StudentsSubmissionsChart