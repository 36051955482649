import { Stack } from '@mui/material'
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from 'moment'
import { useState } from "react";

function RangeSelectorDatetime({onRangeChange}:{onRangeChange:any}) {
const [dateRange, setDateRange] = useState<{
    start:Moment|null,end:Moment|null
}>(
    {
        start:moment().subtract(1,'weeks'),
        end:moment()
    }
)

const handleSelectRange=(date:{start:Moment|null,end:Moment|null})=>{
   setDateRange((prevValue)=>{
    onRangeChange(date)
    return date
   })

}
  return (
    <Stack direction={"row"} spacing={1} width={"300px"}>
      <DatePicker
        slotProps={{ textField: { size: "small", fullWidth: true } }}
        label="Start"
        value={dateRange.start}
        onChange={(newValue) =>
          handleSelectRange({ ...dateRange, start: newValue })
        }
      />
      <DatePicker
        slotProps={{ textField: { size: "small", fullWidth: true } }}
        value={dateRange.end}
        onChange={(newValue) =>
          handleSelectRange({ ...dateRange, end: newValue })
        }
        label="End"
      />
    </Stack>
  );
}

export default RangeSelectorDatetime