import { TCountryCode } from "countries-list";
import { MomentInput } from "moment";
import { AdditionalData } from "./additionalData";

export const CHIPS_COLOR: {
  [key: string]:
    | "primary"
    | "warning"
    | "default"
    | "error"
    | "secondary"
    | "info"
    | "success";
} = {
  initial: "default",
  visa: "info",
  "visa:reminder": "warning",
  "visa:preparing": "secondary",
  "visa:submitted": "success",
  "admission:reminder": "warning",
  "admission:preparing": "secondary",
  "admission:submitted": "success",
  "issue resolution": "error",
  registration: "info",
  "registration:reminder": "warning",
  "deferment:preparing": "secondary",
  "deferment:submitted": "error",
  "pickup:reminder": "secondary",
  "commission:pending": "secondary",
  "completed successfully": "success",
  "to be archived": "default",
  "cooperation done": "success",
};
export interface Passport {
  number: string;
  issueDate: Date;
  expiryDate: Date;
  birthDate: Date;
}
export interface Student {
  [key: string]:
    | string
    | Date
    | undefined
    | MomentInput
    | AdditionalData
    | Passport;
  passport: {
    number: string;
    issueDate: Date;
    expiryDate: Date;
    birthDate: Date;
  };
  refNo: string;
  fullName: string;
  email: string;
  employee: string;
  nationality: string;
  status: string;
  commission: number;
  country: TCountryCode;
  phone: string;
  degree: string;
  program: string;
  university: string;
  additionalData: AdditionalData;
  address?: string;
  arabicName?: string;
  createdAt?: Date;
  updatedAt?: Date;
  _id: string;
}

export enum STUDENT_STATUS {
  "initial" = "initial",
  "visa" = "visa",
  "visa:reminder" = "visa:reminder",
  "visa:preparing" = "visa:preparing",
  "visa:submitted" = "visa:submitted",
  "admission:reminder" = "admission:reminder",
  "admission:preparing" = "admission:preparing",
  "admission:submitted" = "admission:submitted",
  "issue resolution" = "issue resolution",
  "registration" = "registration",
  "registration:reminder" = "registration:reminder",
  "deferment:preparing" = "deferment:preparing",
  "deferment:submitted" = "deferment:submitted",
  "pickup:reminder" = "pickup:reminder",
  "commission:pending" = "commission:pending",
  "completed successfully" = "completed successfully",
  "to be archived" = "to be archived",
  "cooperation done" = "cooperation done",
}