import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed";
import {
  Analytics,
  AttachMoney,
  Dashboard,
  Person,
  School,
  Task,
  Receipt,
} from "@mui/icons-material";
import Logo from "./../../assets/images/logo.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Link, LinkProps } from "react-router-dom";
const drawerWidth = 240;
const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
const routeLinks = [
  { name: "Dashboard", path: "/", icon: <Dashboard /> },
  { name: "Users", path: "/users", icon: <Person />, admin: true },
  { name: "Tasks", path: "/tasks", icon: <Task /> },
  { name: "Submissions", path: "/submissions", icon: <FeedIcon /> },
  { name: "Students", path: "/students", icon: <School /> },
  { name: "Transactions", path: "/transactions", icon: <AttachMoney /> },
  { name: "Analytics", path: "/analytics", icon: <Analytics /> },
  { name: "Invoices", path: "/invoices", icon: <Receipt /> },
];

function Sidebar({ sidebar }: { sidebar: boolean }) {
  const role = useSelector((state: RootState) => state.user.role);
  const permissions = useSelector((state: RootState) => state.user.permissions);
  const allowedResources = permissions.map((res) => {
    if (res.actions.includes("READ")) return res.resource;
  });
  const allowedLink = routeLinks.filter((link) => {
    if (role === "admin") return true;
    if (link.name === "Dashboard") return true;
    if (allowedResources.includes(link.name.toLowerCase())) return true;
  });
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,

        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={sidebar}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img width={60} height={60} src={Logo} alt="5milah Logo" />
      </Box>
      <Divider />
      <List>
        {allowedLink
          .filter((i) => !i.admin)
          .map((link, index) => (
            <ListItem key={link.name} disablePadding>
              <StyledLink to={link.path}>
                <ListItemButton>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.name} />
                </ListItemButton>
              </StyledLink>
            </ListItem>
          ))}
      </List>
      <Divider />
      {role === "admin" && (
        <List>
          {routeLinks
            .filter((i) => i.admin)
            .map((link, index) => (
              <ListItem key={link.name} disablePadding>
                <StyledLink to={link.path}>
                  <ListItemButton>
                    <ListItemIcon>{link.icon}</ListItemIcon>
                    <ListItemText primary={link.name} />
                  </ListItemButton>
                </StyledLink>
              </ListItem>
            ))}
        </List>
      )}
    </Drawer>
  );
}

export default Sidebar;
