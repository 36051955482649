import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  styled,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {
  ChangeEvent,
  FormEvent,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor, EditorTextChangeEvent } from "primereact/editor";
import { useSendEmailMutation } from "../../services/email.service";
import { Email } from "../../types/email";
import { Send } from "@mui/icons-material";
import Joi from "joi";
import { User } from "../../types/users";

const joiSchema = Joi.object({
  from: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  to: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  subject: Joi.string().required(),
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function EmailEditor({
  details,
  open,
  currentUser,
  handleClose,
}: {
  details: any;
  currentUser: User;
  open: boolean;
  handleClose: () => void;
}) {
  const [editorState, setEditorState] = useState<any>();
  const [emailData, setEmailData] = useState<any>({
    to: details?.email,
    from: currentUser?.email,
  });
  const [disableSend, setDisableSend] = useState<boolean>(true);
  const [sendEmail] = useSendEmailMutation();

  useEffect(() => {
    const { value, error } = joiSchema.validate(emailData, {
      abortEarly: false,
    });

    if (error) {
      setDisableSend(true);
    }
    if (value && !error) {
      setDisableSend(false);
    }
    console.log(error);
  }, [emailData, editorState, details]);
  const handleSendEmail = async () => {
    // setEditorState(data)
    setDisableSend(true);
    const emailBody: Email = {
      message: {
        from: emailData.from,
        to: emailData.to,
        text: editorState?.textValue || "",
        html: editorState?.htmlValue || "",
        subject: emailData.subject,
      },
      createdBy: currentUser._id,
      status: "send",
    };

    await sendEmail(emailBody)
      .then((data) => {
        setDisableSend(false);
      })
      .catch((err: Error) => {
        setDisableSend(false);
      });
  };

  const handleEditorStateChange = (event: EditorTextChangeEvent) => {
    setEditorState(event);
  };
  const handleFormChnage = (e: ChangeEvent<HTMLFormElement>) => {
    const { value, name } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  return (
    <div>
      <StyledDialog open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2, textTransform: "capitalize" }}
          id="customized-dialog-title">
          Send Email
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid
            onChange={handleFormChnage}
            component={"form"}
            width={"100%"}
            container
            spacing={1}
            py={2}
            id="email-data"
            direction={"row"}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subject :"
                name="subject"
                id="subject"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={emailData.from}
                fullWidth
                label="From :"
                name="from"
                id="from"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={emailData.to}
                fullWidth
                label="To :"
                name="to"
                id="to"
              />
            </Grid>
          </Grid>
          <Paper>
            <Editor
              style={{ minHeight: "100px" }}
              value={editorState}
              onTextChange={handleEditorStateChange}
              //   editorState={editorState}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={disableSend}
            endIcon={<Send />}
            onClick={handleSendEmail}>
            Send
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}

export default EmailEditor;
