export let BASE_URL = process.env.REACT_APP_URL_PROD || "";

export const envConfig = {
  API_KEY: process.env.REACT_APP_API_KEY || "",
};
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  BASE_URL = process.env.REACT_APP_URL_DEV || "";
} else {
  BASE_URL = process.env.REACT_APP_URL_PROD || "";
}
