import React from 'react';
import './App.css';
import AppRoutes from './routes';
import { BASE_URL, envConfig } from "./config";
import { env } from "process";



function App() {
  fetch(BASE_URL + "health", {
    headers: {
      "api-key": envConfig.API_KEY,
    },
  });
  return (
    <>
  
    <AppRoutes/>
 
    </>
  );
}

export default App;
