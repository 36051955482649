import { FileDownload } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";
import { MRT_RowData, MRT_TableInstance } from "material-react-table";
export const exportActions = [
  {
    icon: <FileDownload />,
    action: "handleExportData",
    label: "Export All Data",
    dataSource: "all",
  },
  {
    icon: <FileDownload />,
    action: "handleExportRows",
    label: "Export All Rows",
    dataSource: "pageRows",
  },
  {
    icon: <FileDownload />,
    action: "handleExportRows",
    label: "Export Selected Rows",
    dataSource: "selectedRows",
  },
  {
    icon: <FileDownload />,
    action: "handleGetVCard",
    label: "Export Contacts",
    dataSource: "selectedRows",
  },
];
export function exportMenuItems<T extends MRT_RowData>(
  items: any[],
  table: MRT_TableInstance<T>,
  actions: any
): React.ReactNode[] {
  return items.map((item, idx) => {
    const data: any = {
      all: null,
      pageRows: table.getPrePaginationRowModel().rows,
      selectedRows: table.getSelectedRowModel().rows,
    };
    if (!actions.hasOwnProperty(item.action)) return null;
    return (
      <MenuItem
        disabled={data[item.dataSource]?.length === 0}
        key={idx}
        onClick={() => actions[item.action](data[item.dataSource])}
        sx={{ m: 0 }}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        {item.label}
      </MenuItem>
    );
  });
}
