import { Grid } from "@mui/material";
import React from "react";
import TasksCard from "./TasksCard";
import SubmissoinsCard from "./SubmissionsCard";

function RecentSection() {
  return (
    <Grid
      item
      container
      justifyContent={"space-between"}
      direction={"column"}
      //   spacing={2}
      sx={{
        // flexBasis: { sm: "300px", xl: "350px", m: "300px" },
        height: "100%",
        alignItems: "center",
        flexWrap: "nowrap",
      }}>
      <Grid
        item
        sx={{
          boxSizing: "border-box",
          width: "100%",
          flexBasis: 0,
          paddingBottom: 1,
          height: "50%",
          justifyContent: "flex-start",
        }}>
        <TasksCard />
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          height: "50%",
          paddingTop: 1,
          justifyContent: "flex-start",
        }}>
        <SubmissoinsCard />
      </Grid>
    </Grid>
  );
}

export default RecentSection;
