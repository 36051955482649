import { Grid } from "@mui/material";
import React from "react";
import AnalyticCard from "./AnalyticCard";
import { Analytics } from "../../types/analytics";

const calculatePercentage = (array: any[]) => {
  if (!array) return 0;
  if (array.length <= 1) return 0;
  const currentTotal = array[array.length - 1].total;
  const previousTotal = array[array.length - 2].total;
  const percentageChange =
    ((currentTotal - previousTotal) / previousTotal) * 100;

  return percentageChange;
};

function AnalyticCards({
  analytics,
  cardsData,
  period,
  allowedResources,
}: {
  analytics: Analytics;
  cardsData: any;
  period: any;
  allowedResources: any[];
}) {
  return (
    <Grid
      columns={{ xs: 1, md: 3 }}
      container
      direction={"row"}
      justifyContent={"flex-start"}
      spacing={2}
      sx={{
        flexWrap: { xs: "wrap", md: "wrap", lg: "nowrap" },
      }}>
      {allowedResources.includes("students") && (
        <Grid item xs={12} md={4}>
          <AnalyticCard
            title="Total Students"
            value={analytics?.students?.count}
            comment={`There is ${
              calculatePercentage(cardsData?.students[period]?.stats as any) > 0
                ? "an increase in students"
                : "a decrease in students"
            }`}
            percentage={calculatePercentage(
              cardsData?.students[period]?.stats as any
            )}
          />
        </Grid>
      )}
      {allowedResources.includes("submissions") && (
        <Grid item xs={12} md={4}>
          <AnalyticCard
            title="Total Submissions"
            value={analytics?.submissions?.count}
            comment={`There is ${
              calculatePercentage(cardsData.submissions[period]?.stats as any) >
              0
                ? "an increase in submissions"
                : "a decrease in submissions"
            }`}
            percentage={calculatePercentage(
              cardsData.submissions[period]?.stats as any
            )}
          />
        </Grid>
      )}
      {allowedResources.includes("transactions") && (
        <Grid item xs={12} md={4}>
          <AnalyticCard
            title="Total Transactions"
            value={analytics?.transactions?.count}
            comment={`There is ${
              calculatePercentage(
                cardsData?.transactions[period]?.stats as any
              ) > 0
                ? "an increase in submissions"
                : "a decrease in submissions"
            }`}
            percentage={calculatePercentage(
              cardsData?.transactions[period]?.stats as any
            )}
          />
        </Grid>
      )}
      {allowedResources.includes("tasks") && (
        <Grid item xs={12} md={4}>
          <AnalyticCard
            title="Completed Tasks"
            value={analytics?.tasks?.count}
            comment={`There is ${
              calculatePercentage(
                cardsData?.transactions[period]?.stats as any
              ) > 0
                ? "an increase in submissions"
                : "a decrease in submissions"
            }`}
            percentage={calculatePercentage(
              cardsData?.tasks[period]?.stats as any
            )}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default AnalyticCards;
