import { User } from '../../types/users';
import { Box, Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Delete, Person } from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import UserForm from './UserForm';
import { useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation } from '../../services/users.service';

function UsersTable({ rows }: { rows: User[] }) {

  const [selectedRow,setSelectedRow]=useState<User|null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [updateUser]=useUpdateUserMutation()
  const [createUser]=useCreateUserMutation()
  const [deleteUser]=useDeleteUserMutation()
    const headers: { key: keyof User; label: string }[] = [
        {
          key: "username",
          label: "Username",
        },
        {
          key: "role",
          label: "Role",
        },
        {
          key: "email",
          label: "Email",
        },
        
        { key: "createdAt", label: "Created At" },
        { key: "updatedAt", label: "Updated At" },
      ];
      const renderCells=(item:{key:keyof User,label:string},row:User)=>{
        switch(item.key){
        
            case 'createdAt':
            return  moment(row[item.key]).fromNow()
            case 'updatedAt':
            return  moment(row[item.key]).fromNow()
          default:
            return  row[item.key]?.toLocaleString()
      
        }
       }


       const handleCreate=()=>{
        setEdit(false)
        setSelectedRow(null)
        setOpenModal(true)
      }  

      const handleSelectUser=(id:any)=>{
        const user=rows.find(t=>t._id===id);
        if(user){
          setSelectedRow(user)
          setOpenModal(true)
          setEdit(true)
        }
      
       }
       const handleClose=()=>{
        setOpenModal(false)
        setEdit(false)
        setSelectedRow(null)
       }
       const handleSubmitForm=(user:User)=>{
      console.log(user)

      if(edit&&selectedRow){
        updateUser({...user}).then(data=>{
          handleClose();
          setEdit(false);
          setSelectedRow(null)
        }).catch((err:Error)=>{
          console.log(err)
        })
      
      }else{
      
        console.log(user)
        createUser(user).then(data=>{
          handleClose();
          setEdit(false);
          setSelectedRow(null)
        }).catch((err:Error)=>{
          console.log(err)
        })
      }
       }
       const handleDelete=(_id:string)=>{
        const user=rows.find(t=>t._id===_id);
        if(window.confirm(`Are you sure you want to delete ${user?.username}`)){
          deleteUser(_id).then(res=>{
            setSelectedRow(null)
          }).catch((err:Error)=>{
            console.log(err)
          })
        }
      }
     return (
       <Grid
         container
         sx={{
           flexDirection: "column",
           gap: 2,
           height: "100%",
           flexWrap: "nowrap",
         }}>
         {openModal && (
           <UserForm
             submitHanlder={handleSubmitForm}
             edit={!!selectedRow}
             open={openModal}
             handleClose={handleClose}
             selectedUser={selectedRow}
           />
         )}
         <TableContainer
           component={Paper}
           sx={{
             boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
             borderRadius: "8px",

             width: "100%",
             height: "100%",
           }}>
           <Box
             sx={{
               m: 1,
             }}>
             <Button startIcon={<Person />} onClick={handleCreate}>
               Create
             </Button>
           </Box>
           <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
             <TableHead>
               <TableRow>
                 {headers.map((item) => (
                   <TableCell key={item.key}>{item.label}</TableCell>
                 ))}
                 <TableCell key={"actions"}>Actions</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {rows.map((row) => (
                 <TableRow
                   onClick={() => handleSelectUser(row._id)}
                   selected={selectedRow?._id === row._id}
                   key={row._id}
                   sx={{
                     "&:last-child td, &:last-child th": { border: 0 },
                     cursor: "pointer",
                   }}>
                   {headers.map((item) => (
                     <TableCell key={item.key}>
                       {renderCells(item, row)}
                     </TableCell>
                   ))}
                   <TableCell
                     key={`actions-${row._id}`}
                     onClick={(e) => {
                       e.stopPropagation();
                       handleDelete(row._id);
                     }}>
                     <Button startIcon={<Delete />} color="error" />
                   </TableCell>
                 </TableRow>
               ))}
             </TableBody>
           </Table>
         </TableContainer>
       </Grid>
     );
}

export default UsersTable