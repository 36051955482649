import { useLayoutEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { User } from "../../types/users";
import FormBuilder from "../controls/FormBuilder";
import { Student } from "../../types/students";
import { Invoice } from "../../types/invoices";
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "80%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const initialInvoiceData: Invoice = {
  refNo: "",
  student: "",
  service: "",
  amount: 0,
  status: "",
  paidAmount: 0,
  paymentDate: "",
  method: "",
  receiptNumber: "",
  additionalDetails: {},
  user: "",
  description: "",
  currency: "",
  _id: "",
};
function InvoicesForm({
  selectedInvoice,
  users,
  handleClose,
  open,
  edit,
  students,
  submitHanlder,
}: {
  selectedInvoice: Invoice | null;
  users: User[];
  handleClose: any;
  open: boolean;
  edit: boolean;
  students: Student[];
  submitHanlder: any;
}) {
  const [invoiceData, setInvoiceData] = useState<Invoice>(initialInvoiceData);
  useLayoutEffect(() => {
    if (selectedInvoice) setInvoiceData(selectedInvoice);
    return () => {
      setInvoiceData(initialInvoiceData);
    };
  }, [selectedInvoice]);
  const controls = useMemo(
    () => [
      {
        type: "select",
        control: {
          otherProps: { fullWidth: true },
          additional: { fullWidth: true, required: false },
          id: "student",
          name: "student",
          label: "Student",
          options: [
            ...students.map((student) => ({
              label: student.fullName,
              value: student._id,
            })),
          ],
        },
      },
      {
        type: "select",
        control: {
          otherProps: { fullWidth: true },
          additional: { fullWidth: true, required: false },
          id: "user",
          name: "user",
          label: "Received By",
          options: [
            ...users.map((user) => ({
              label: user.username,
              value: user._id,
            })),
          ],
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "service",
          name: "service",
          label: "Service",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true, type: "number" },
          otherProps: { fullWidth: true },
          id: "amount",
          name: "amount",
          label: "Amount",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true, type: "number" },
          otherProps: { fullWidth: true },
          id: "paidAmount",
          name: "paidAmount",
          label: "Paid Amount",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "status",
          name: "status",
          label: "Status",
        },
      },
      {
        type: "datetime",
        control: {
          timezone: "system",
          label: "Payment Date",
          id: "paymentDate",
          name: "paymentDate",
          additional: { fullWidth: true, required: true },
        },
      },
      {
        type: "input",
        control: {
          additional: {
            fullWidth: true,

            required: true,
          },
          otherProps: {
            fullWidth: true,
          },
          id: "method",
          name: "method",
          label: "Method",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "receiptNumber",
          name: "receiptNumber",
          label: "Receipt Number",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "additionalDetails.note",
          name: "additionalDetails.note",
          label: "Notes",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "description",
          name: "description",
          label: "Description",
        },
      },
      {
        type: "input",
        control: {
          additional: { fullWidth: true, required: true },
          otherProps: { fullWidth: true },
          id: "currency",
          name: "currency",
          label: "Currency",
        },
      },
    ],
    [students, users]
  );

  return (
    <StyledDialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {invoiceData.refNo?.toUpperCase() || "Create New Invoice"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik
          enableReinitialize
          initialValues={invoiceData}
          onSubmit={submitHanlder}>
          {({ handleSubmit }) => (
            <Box
              component={"form"}
              id="invoices"
              onSubmit={(e) => handleSubmit(e)}>
              <FormBuilder controls={controls} />
            </Box>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form="invoices">
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default InvoicesForm;
