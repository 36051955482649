import { Box } from '@mui/material'
import React from 'react'
import Loader from '../../components/common/Loader';
import { useGetDashboardQuery } from '../../services/analytics.service';
import { useNavigate } from 'react-router-dom';
import Analytics from '../../components/Analytics';

function AnalyticsPage() {
  const navigator = useNavigate()

  const { data, error, isLoading } = useGetDashboardQuery('Analytics');
  const isLoadingData=isLoading

  if(error){
      navigator('/login')
  }

  return (
  
      <Box height={'100%'} width={'100%'}>
      {isLoadingData?<Loader/>: <Analytics />}
      </Box>
     )
}

export default AnalyticsPage