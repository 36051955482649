import { useState } from "react";
import { useGetTransactionsCalculationMutation } from "../../services/tansactions.service";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { User } from "../../types/users";
import { Student } from "../../types/students";
import { QueryStats } from "@mui/icons-material";
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "80%",
    boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function TransactionsCalculation({
  users,
  handleClose,
  open,
  students,
}: {
  users: User[];
  handleClose: any;
  open: boolean;
  students: Student[];
}) {
  interface Data {
    totalAmount: number;
    _id: {
      student?: string;
      transactionType: string;
      currency?: string;
      user?: string;
    };
  }
  const [getCalculations] = useGetTransactionsCalculationMutation();
  const [data, setData] = useState<Data[]>([]);
  const [student, setStudent] = useState<string>("");
  const [user, setUser] = useState<string>(users[0]._id);
  const [filter, setFilter] = useState<any>({
    match: { user: user },
    groupBy: { transactionType: "$transactionType", currency: "$currency" },
  });
  const getData = () => {
    getCalculations(filter).then((res: any) => {
      const data = res?.data;
      setData(data);
    });
  };
  const handleSelectStudent = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setStudent(value as string);
    if (value) {
      setFilter({
        match: { student: value, user: user },
        groupBy: { transactionType: "$transactionType", currency: "$currency" },
      });
    }
  };
  const handleSelectUser = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setUser(event.target.value as string);
    if (value) {
      setFilter({
        match: { user: value, student: student },
        groupBy: { transactionType: "$transactionType", currency: "$currency" },
      });
    }
  };
  return (
    <StyledDialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Transaction Per User
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item container spacing={1}>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <InputLabel id="user">User</InputLabel>
                <Select
                  labelId="user"
                  id="user"
                  value={user}
                  label="User"
                  onChange={handleSelectUser}>
                  {users.map((user) => (
                    <MenuItem value={user._id} key={user._id}>
                      {user.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <InputLabel id="student">Student</InputLabel>
                <Select
                  labelId="student"
                  id="student"
                  value={student}
                  label="Student"
                  onChange={handleSelectStudent}>
                  <MenuItem value={""} key={0}>
                    N/A
                  </MenuItem>
                  {students.map((student) => (
                    <MenuItem value={student._id} key={student._id}>
                      {student.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Grid item sm={12}>
            <Stack spacing={2} width={"100%"} alignItems={"center"}>
              {data?.map((item) => (
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}>
                  <Typography variant="body1" textTransform={"capitalize"}>
                    {item._id.transactionType}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography variant="body1" fontWeight={"bold"}>
                    {item.totalAmount} {item._id.currency}
                  </Typography>
                </Paper>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={getData} form="students" endIcon={<QueryStats />}>
          Run Query
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default TransactionsCalculation;
