import {
  ButtonGroup,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  useTheme,
} from "@mui/material";
import { ApexOptions } from "apexcharts";
import { ChangeEventHandler, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  CardStats,
  DailyStats,
  MonthlyStats,
  WeeklyStats,
} from "../../types/analytics";
import { ImportantDevicesTwoTone, ShowChart } from "@mui/icons-material";
type DataStats = WeeklyStats | MonthlyStats | DailyStats;

const SelectPeriod = ({
  period,
  handleSelectPeriod,
}: {
  period: Periods;
  handleSelectPeriod: any;
}) => {
  return (
    <Select
      size="small"
      sx={{ textTransform: "capitalize" }}
      labelId="period"
      id="period"
      value={period}
      onChange={handleSelectPeriod}>
      {["daily", "weekly", "monthly"].map((p) => (
        <MenuItem sx={{ textTransform: "capitalize" }} key={p} value={p}>
          {p}
        </MenuItem>
      ))}
    </Select>
  );
};
const generateChartData = (data: DataStats[], name: string) => {
  if (!data) return { name: name, data: [], dates: [] };
  return data.reduce(
    (acc: any, curr) => {
      acc.data.push(curr.total);
      acc.dates.push(curr.date[0].formattedDate);
      return acc;
    },
    { name: name, data: [], dates: [] }
  );
};
type Periods = "daily" | "monthly" | "weekly";
function Chart({
  data,
  period,
  handleSelectPeriod,
}: {
  data: CardStats;
  period: Periods;
  handleSelectPeriod: any;
}) {
  const theme = useTheme();
  const line = theme.palette.divider;
  const [dataSource, setDataSource] = useState<
    "students" | "submissions" | "transactions"
  >("students");
  const [chartType, setChartType] = useState<"bar" | "area" | "line">("area");

  const chartData = generateChartData(
    data[dataSource][period]?.stats,
    dataSource
  );

  const chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        tools: {
          // customIcons: [
          //   {
          //     icon: <ShowChart/>,
          //     title: "Chart Type",
          //     index: 0,
          //     // class: string
          //     click: () => {
          //       setChartType("area");
          //     },
          //   },
          //   {
          //     icon: '<img src="/bar.png" width="20">',
          //     title: "Chart Type",
          //     index: 0,
          //     // class: string
          //     click: () => {
          //       setChartType("bar");
          //     },
          //   },
          //   {
          //     icon: '<img src="/line.png" width="20">',
          //     title: "Chart Type",
          //     index: 0,
          //     // class: string
          //     click: () => {
          //       setChartType("line");
          //     },
          //   },
          // ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: Array.from(new Set(chartData.dates)),
      axisBorder: {
        show: true,
        color: line,
      },
    },
    grid: {
      borderColor: line,
    },
    //   tooltip: {
    //     x: {
    //       format: 'dd/MM/yy HH:mm'
    //     },
    //   },
  };

  const handleAddSource = (e: SelectChangeEvent) => {
    const source = e.target.value as
      | "students"
      | "submissions"
      | "transactions";
    setDataSource(source);
    // const sources=[...dataSource]
    // const stats={...cardsData}
    // const chartDataTemp=[...chartData]
    // if(dataSource.includes(source)&&dataSource.length>1){
    //     setDataSource([...sources.filter(s=>s!==source)]);
    //     setChartData([...chartDataTemp.filter(data=>data.name!==source)])
    // }else{
    //     setDataSource([source])
    //     const data=stats[source][period].stats
    //     setChartData([...chartData,generateChartData(data,source)])
    // }
  };
  return (
    <Grid
      container
      direction={"column"}
      sx={{
        height: "100%",
        // height: { xs: "350px", xl: "70vh", lg: "65vh", md: "50vh" },
        width: "100%",
      }}>
      <Grid flex={1} item container direction={"column"}>
        <Grid
          container
          item
          direction={"row"}
          sx={{ alignItems: "center", py: 1 }}
          gap={1}>
          <ButtonGroup sx={{ gap: 1 }}>
            <Select
              sx={{ textTransform: "capitalize" }}
              size="small"
              value={dataSource}
              onChange={handleAddSource}>
              {["students", "submissions", "transactions"].map((source) => (
                <MenuItem
                  sx={{ textTransform: "capitalize" }}
                  key={source}
                  value={source}>
                  {source}
                </MenuItem>
              ))}
            </Select>
            <SelectPeriod
              handleSelectPeriod={handleSelectPeriod}
              period={period}
            />
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid flex={10} item>
        <ReactApexChart
          height={"100%"}
          type={chartType}
          options={chartOptions}
          series={[chartData]}
        />
      </Grid>
    </Grid>
  );
}

export default Chart;
