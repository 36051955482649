import {
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";

import { Submission } from "../../types/submissions";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  AddBox,
  Delete,
  Edit,
  Email as EmailIcon,
  FileDownload,
  ImportExport,
  WhatsApp,
} from "@mui/icons-material";
import SubmissionForm from "./SubmissionForm";
import React, { useEffect, useMemo, useState } from "react";
import {
  useCreateSubmissionMutation,
  useDeleteSubmissionMutation,
  useTransferSubmissionMutation,
  useUpdateSubmissionMutation,
} from "../../services/submissions.service";
import SubmissionAdditionalInfo from "./SubmissionAdditionalInfo";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import { useSnackbar } from "notistack";
import { User } from "../../types/users";
import { CHIPS_COLOR } from "../../types/submissions";
import SubmissionsImport from "./SubmissionsImport";
import { download, generateCsv, mkConfig } from "export-to-csv";
import moment from "moment";
import { exportActions, exportMenuItems } from "../common/ExportMenu";
import EmailEditor from "../common/EmailEditor";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import vCard from "vcf";
import { Email } from "../../types/email";
import { useSendEmailMutation } from "../../services/email.service";

const acitonItems = [
  {
    icon: <Edit color="primary" fontSize="small" />,
    action: "handleEdit",
    label: "Edit",
  },
  {
    icon: <SendTimeExtensionIcon color="secondary" fontSize="small" />,
    action: "transferToStudent",
    label: " Confirm as Student",
  },
  {
    icon: <WhatsApp color="success" fontSize="small" />,
    action: "whatsApp",
    label: "WhatsApp",
  },
  {
    icon: <EmailIcon color="success" fontSize="small" />,
    action: "handleSendEmail",
    label: "Send Email",
  },
  {
    icon: <Delete fontSize="small" color="error" />,
    action: "handleDelete",
    label: "Delete",
  },
];
const nameAndDegree = (row: Submission) => {
  const nameArray = row?.fullName?.split(" ");
  const firstName = nameArray?.[0];
  const lastName =
    nameArray.length > 2 ? nameArray?.[nameArray.length - 1] : "";
  const degree = row.degree;
  const program = row.program;
  return `${firstName} ${lastName} - ${degree} ${program}`;
};
const actionMenu = (
  items: any[],
  row: MRT_Row<Submission>,
  actions: any,
  closeMenu: any
): React.ReactNode[] => {
  return items.map((item, idx) => (
    <MenuItem
      key={idx}
      onClick={() => {
        actions[item.action](row);
        closeMenu();
      }}
      sx={{ m: 0 }}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      {item.label}
    </MenuItem>
  ));
};

function SubmissionsTable({
  rows,
  users,
}: {
  rows: Submission[];
  users: User[];
}) {
  const [dataTable, setDataTable] = useState<Submission[]>(rows);
  const [createSubmission] = useCreateSubmissionMutation();
  const [updateSubmission] = useUpdateSubmissionMutation();
  const [deleteSubmission] = useDeleteSubmissionMutation();
  const [transferSubmission] = useTransferSubmissionMutation();
  const currentUser = useSelector((state: RootState) => state.user);
  const isAdmin = currentUser.role === "admin";
  const { enqueueSnackbar } = useSnackbar();
  const [sendEmail] = useSendEmailMutation();
  const [selectedRow, setSelectedRow] = useState<Submission | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEmail, setOpenEmail] = useState<boolean>(false);
  const [openImport, setOpenImport] = useState<boolean>(false);
  const [exportMenuEl, setExportMenuEl] = React.useState<null | HTMLElement>(
    null
  );
  const [filter, setFilter] = useState<string | "all" | "today" | "yesterday">(
    "all"
  );
  const [details, setDetails] = useState<{
    type: string;
    submission: Submission;
  } | null>(null);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    setDataTable(rows);
  }, [rows]);
  const columns = useMemo<MRT_ColumnDef<Submission>[]>(
    () => [
      // {
      //   id: "Submissions",

      //   Cell: (cell) => (
      //     <>{rows.filter((r) => r.email === cell.row.original.email).length}</>
      //   ),
      //   size: 20,
      //   header: "Submissions",
      // },
      {
        id: "createdAt",
        accessorFn: ({ createdAt }) => (
          <>
            {moment(createdAt).format("DD/MM/yyyy hh:mm  A").toLocaleString()}
            <p style={{ margin: 0, padding: 0, color: "gray" }}>
              {moment(createdAt).fromNow()}
            </p>
          </>
        ),
        header: "Submission Date",
        filterVariant: "date-range",
      },
      {
        id: "refNo",
        accessorKey: "refNo",
        header: "Ref No",
        size: 30,
      },
      {
        id: "name-degree",
        Cell: (cell) => nameAndDegree(cell.row.original),
        header: "Name-Degree",
      },
      {
        id: "email",
        accessorKey: "email",
        header: "Email",
      },
      {
        id: "phone",
        accessorKey: "phone",
        header: "Phone",
      },
      {
        id: "employee",
        header: "Employee",
        size: 50,
        accessorFn: (row) =>
          users.find((user) => user._id === row.employee)?.username,
      },
      {
        id: "status",
        size: 50,
        accessorFn: ({ status }) => (
          <Chip
            size="small"
            sx={{
              borderRadius: "8px",
            }}
            label={status}
            color={CHIPS_COLOR[status]}
          />
        ),
        header: "Status",
      },
      {
        id: "degree",
        accessorKey: "degree",
        header: "Degree",
      },
      {
        id: "program",
        accessorKey: "program",
        header: "Program",
      },
      {
        id: "fullName",
        accessorKey: "fullName",
        header: "Full Name",
      },
      {
        id: "country",
        size: 50,
        accessorKey: "country",
        header: "Country",
      },
      {
        id: "nationality",
        size: 50,
        accessorKey: "nationality",
        header: "Nationality",
      },
    ],
    [users]
  );
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleGetVCard = async (dataTable: MRT_Row<Submission>[]) => {
    const data = dataTable.map((d) => d.original);
    let cardString = "";
    data.forEach((item) => {
      const nameAndDeg = nameAndDegree(item);
      const card = new vCard();
      card.add("fn", nameAndDeg);
      card.add("tel", item.phone);
      card.add("email", item.email);
      card.add("adr", item.country);
      card.add("title", item.degree);
      cardString += card.toString() + "\n";
    });
    const blob = new Blob([cardString], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const cardName = new Date().toISOString();
    a.download = `${"card-"}${cardName}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    reader.onloadend = async function () {
      let base64String = reader.result;
      const emailBody: Email = {
        message: {
          from: `${currentUser?.username} <${currentUser?.email}>`,
          to: `${currentUser?.username} <${currentUser?.email}>`,
          text: `${"card-"}${cardName}.vcf`,
          subject: `${"card-"}${cardName}.vcf`,
          attachments: [
            {
              filename: `${"card-"}${cardName}.vcf`,
              path: base64String,
            },
          ],
        },
        createdBy: currentUser._id,
        status: "send",
      };
      await sendEmail(emailBody);
    };
  };
  const handleExportRows = (dataTable: MRT_Row<Submission>[]) => {
    let rowData = dataTable.map((row) => row.original);
    rowData = rowData.map((data) => ({
      ...data,
      employee: users.find((user) => user._id === data.employee)?.username,
      passport: JSON.stringify(data.passport),
    })) as any;
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    let rowData = dataTable.map((data) => ({
      ...data,
      employee: users.find((user) => user._id === data.employee)?.username,
      passport: JSON.stringify(data.passport),
    })) as any;
    rowData = generateCsv(csvConfig)(rowData);
    download(csvConfig)(rowData);
  };
  const table = useMaterialReactTable({
    columns,
    data: dataTable, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableSelectAll: true,
    enableMultiRowSelection: true,
    getRowId: (originalRow) => originalRow._id,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableTopToolbar: true,
    enableRowSelection: true,
    enableRowPinning: true,
    enableFullScreenToggle: false,
    muiTableBodyRowProps: ({ row, table }) => {
      const multi =
        rows.filter((item) => {
          return (
            item.email === row.original.email ||
            item.phone === row.original.phone
          );
        }).length > 1;
      return {
        sx: {
          //Set a fixed height for pinned rows
          bgcolor: multi ? "#f4dab961" : "",
        },
      };
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: {
          xs: "10vh",
          sm: "45vh",
          md: "50vh",
          lg: "70vh",
          xl: "75vh",
        },
      },
    },
    initialState: {
      columnVisibility: {},
      density: "compact",
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: { left: ["mrt-row-select", "mrt-row-actions"] },
    },
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 10, //adjust the size of the row select column
      },
      "mrt-row-pin": {
        size: 5,
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: "8px",
      },
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    enableRowActions: true,
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      ...actionMenu(
        acitonItems,
        row,
        {
          handleEdit,
          handleDelete,
          transferToStudent,
          whatsApp,
          handleSendEmail,
        },
        closeMenu
      ),
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box>
        <ButtonGroup
          sx={{
            flexWrap: "wrap",
          }}>
          <Select size="small" value={filter} onChange={filterData}>
            <MenuItem value={"all"}>All Data</MenuItem>
            <MenuItem value={"today"}>Today</MenuItem>
            <MenuItem value={"yesterday"}>Yesterday</MenuItem>
          </Select>
          {isAdmin && (
            <>
              <Button startIcon={<AddBox />} onClick={handleCreate}>
                Create
              </Button>

              <Button startIcon={<ImportExport />} onClick={handleImportOpen}>
                Import
              </Button>
            </>
          )}
          <Button
            id="demo-positioned-button"
            aria-controls={
              Boolean(exportMenuEl) ? "demo-positioned-menu" : undefined
            }
            aria-haspopup="true"
            startIcon={<FileDownload />}
            aria-expanded={Boolean(exportMenuEl) ? "true" : undefined}
            onClick={(event: any) => {
              setExportMenuEl(event.target);
            }}>
            Export
          </Button>
        </ButtonGroup>
      </Box>
    ),
  });

  // const renderCells=(item:{key:keyof Submission,label:string},row:Submission)=>{
  //   switch(item.key){
  //       case 'createdAt':
  //       return  moment(row[item.key]).toLocaleString()?.split('G')[0];
  //       case 'country':
  //       return  getCountryData(row[item.key])?.name;
  //       case 'employee':
  //           return  data&&data.find(user=>user._id===row[item.key])?.username;
  //     default:
  //       return  row[item.key]?.toLocaleString()

  //   }
  //  }
  const whatsApp = (row: MRT_Row<Submission>) => {
    const phone = row.original.phone;
    const href = `https://wa.me/${phone}`;
    const newwindow = window.open(href, "whatsApp", "height=800,width=900");
    if (window.focus!) {
      newwindow?.focus();
    }
    return false;
  };
  const handleEdit = (row: MRT_Row<Submission>) => {
    setSelectedRow(row.original);
    setOpenModal(true);
    setEdit(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setEdit(false);
    setSelectedRow(null);
  };
  const handleCloseImport = () => {
    setOpenImport(false);
  };
  const handleSubmitForm = (submission: Submission) => {
    for (const key in submission) {
      if (submission[key] === "" || submission[key] === null) {
        delete submission[key];
      }
      if (submission[key] === "N/A") {
        submission[key] = null;
      }
    }
    if (edit && selectedRow) {
      updateSubmission(submission)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    } else {
      createSubmission(submission)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    }
  };
  const handleCreate = () => {
    setEdit(false);
    setSelectedRow(null);
    setOpenModal(true);
  };
  const handleImportOpen = () => {
    setOpenImport(true);
  };
  const handleSendEmail = (row: MRT_Row<Submission>) => {
    setSelectedRow(row.original);
    setOpenEmail(true);
  };
  const handleDelete = async (row: MRT_Row<Submission>) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to delete ${row?.original.fullName}`
        )
      ) {
        await deleteSubmission(row.id);
        setSelectedRow(null);
      }
      return row;
    } catch (error) {
      console.log(error);
    }
  };
  const transferToStudent = async (row: MRT_Row<Submission>) => {
    try {
      let student = await transferSubmission(row.id).unwrap();
      enqueueSnackbar("Submission Transfered Successfully", {
        variant: "success",
        autoHideDuration: 1000,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseDetials = () => {
    setDetails(null);
  };
  const filterData = (e: SelectChangeEvent) => {
    const value = e.target.value;
    const refDay = value === "today" ? moment() : moment().subtract(1, "day");
    const filtered = rows.filter((item) => {
      const created = moment(item.createdAt);
      return created.isSame(refDay, "day");
    });
    setDataTable(filtered);
    setFilter(value);
    if (value === "all") {
      setDataTable(rows);
    }
  };

  return (
    <>
      {openEmail && (
        <EmailEditor
          open={openEmail}
          currentUser={currentUser}
          details={selectedRow}
          handleClose={() => setOpenEmail(false)}
        />
      )}
      <Menu
        anchorEl={exportMenuEl}
        open={Boolean(exportMenuEl)}
        onClose={() => {
          setExportMenuEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        {exportMenuItems<Submission>(exportActions, table, {
          ...(isAdmin && { handleExportData, handleExportRows }),
          handleGetVCard,
        })}
      </Menu>
      {details && (
        <SubmissionAdditionalInfo
          open={!!details}
          handleClose={handleCloseDetials}
          details={details}
        />
      )}
      <Grid
        container
        sx={{
          flexDirection: "column",
          gap: 2,
          height: "100%",
          flexWrap: "nowrap",
        }}>
        {/* <Grid item sm={12}> */}
        {openModal && (
          <SubmissionForm
            submitHanlder={handleSubmitForm}
            edit={!!selectedRow}
            open={openModal}
            handleClose={handleClose}
            selectedSubmission={selectedRow}
            users={users}
          />
        )}
        {openImport && (
          <SubmissionsImport
            handleClose={handleCloseImport}
            open={openImport}
          />
        )}

        {/* </Grid> */}

        {/* <Paper sx={{ width: "100%", height: "100%", overflow: "scroll" }}> */}
        {<MaterialReactTable table={table} />}
        {/* </Paper> */}
      </Grid>
    </>
  );
}

export default SubmissionsTable;
