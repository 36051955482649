import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { User } from "../../types/users";
import { jwtDecode } from "jwt-decode";

const initialState: User = {
  username: "",

  role: "",

  email: "",
  access_token: null,
  tasks: [],

  submissions: [],
  permissions: [],
  transactions: [],
  _id: "",
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      const token = action.payload.access_token || "";
      const decoded = jwtDecode(token);
      const userData = {
        ...decoded,
        access_token: token,
      };
      return userData as User;
    },
    logout: (state) => {
      state = initialState;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = usersSlice.actions;

export default usersSlice.reducer;
