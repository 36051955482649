import { Box, Card, CardContent, Chip, Divider, Stack, Typography } from '@mui/material';
import { CHIPS_COLOR } from '../../types/students';
import { teal } from '@mui/material/colors';
import { useGetRecentSubmissionsQuery } from '../../services/submissions.service';

function SubmissoinsCard() {
    const { data } = useGetRecentSubmissionsQuery('Submissions');
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        // "&:hover": {
        //   boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.15);",
        // },

        boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
      }}>
      <Box p={1}>
        <Typography variant="h6" color={"black"}>
          Recent Submissions
        </Typography>
      </Box>
      <Divider />
      <CardContent sx={{ height: "75%", overflow: "scroll" }}>
        <Stack direction={"column"} divider={<Divider />}>
          {data?.map((submission) => (
            <Stack
              justifyContent={"space-between"}
              key={submission._id}
              direction={"row"}
              spacing={1}
              p={1}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {submission.fullName}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {submission.degree}
              </Typography>
              <Chip
                sx={{ borderRadius: "8px", marginLeftt: "auto" }}
                size="small"
                label={submission.status}
                color={CHIPS_COLOR[submission.status]}
              />
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default SubmissoinsCard