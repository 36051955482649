import TextField from './TextField/TextField';
import SelectField from './SelectField/SelectField';
import RadioButton from './RadioButton/RadioButton';
import DateTimeInput from './DateTimeInput/DateTimeInput';
import { Box, Grid } from '@mui/material';

function FormBuilder({controls}:{controls:any}) {
  const RenderField = ({ type, control }: { type: string; control: any }) => {
    switch (type) {
      case "input":
        return <TextField control={control} otherProps={control.otherProps} />;
      case "select":
        return (
          <SelectField control={control} otherProps={control.otherProps} />
        );
      case "radio":
        return (
          <RadioButton control={control} otherProps={control.otherProps} />
        );
      case "datetime":
        return (
          <DateTimeInput
            type={type}
            otherProps={control.otherProps}
            control={control}
          />
        );
    }
  };

  return (
    <Box m={2}>
      <Grid container spacing={2} direction={"column"}>
        {controls.map((control: any, idx: number) => {
          return (
            <Grid item key={idx}>
              {RenderField(control)}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default FormBuilder