import { Box } from '@mui/material'
import React from 'react'
import Loader from '../../components/common/Loader'
import UsersTable from '../../components/Users/Table'
import { useGetUsersQuery } from '../../services/users.service';
import { useNavigate } from 'react-router-dom';

function UsersPage() {
  const navigator = useNavigate()
    const { data, error, isLoading } = useGetUsersQuery("Users");


  if(error) navigator('/')
  if(!error&&isLoading) return <Loader/>;

  return (
    <Box height={'100%'} width={'100%'}>
    {<UsersTable rows={data||[]}/>}
    </Box>
  )
}

export default UsersPage