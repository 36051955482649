import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CHIPS_COLOR, Task } from "../../types/tasks";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useGetUsersQuery } from "../../services/users.service";
import TaskForm from "./TaskForm";
import {
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} from "../../services/tasks.service";
import { Delete, TaskAlt } from "@mui/icons-material";
import moment from "moment";
import Loader from "../common/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export default function TaskTable({ rows }: { rows: Task[] }) {
  const { data, isLoading } = useGetUsersQuery("users");
  const currentUser = useSelector((state: RootState) => state.user);
  const [selectedRow, setSelectedRow] = useState<Task | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>("all");
  const [tableData, setTableData] = useState<Task[]>(rows);
  const [updateTask] = useUpdateTaskMutation();
  const [createTask] = useCreateTaskMutation();
  const [deleteTask] = useDeleteTaskMutation();
  useEffect(() => {
    setTableData(rows);
  }, [rows]);
  const headers: { key: keyof Task; label: string }[] = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "refNo",
      label: "Reference No",
    },

    {
      key: "description",
      label: "Description",
    },
    {
      key: "status",
      label: "Status",
    },

    { key: "dueDate", label: "Due Date" },
    { key: "urgency", label: "Urgency" },
    { key: "createdBy", label: "Created By" },
    { key: "assignedTo", label: "Assigned To" },
  ];

  const renderCells = (item: { key: keyof Task; label: string }, row: Task) => {
    switch (item.key) {
      case "status":
        return (
          <Chip
            sx={{
              borderRadius: "8px",
            }}
            label={row[item.key]}
            color={CHIPS_COLOR[row[item.key]]}
            size="small"
          />
        );
      case "urgency":
        return (
          <Chip
            sx={{ textTransform: "uppercase", borderRadius: "8px" }}
            label={row[item.key]}
            color={CHIPS_COLOR[row[item.key]]}
            size="small"
          />
        );
      case "createdBy":
        return (
          data && data.find((user) => user._id === row[item.key])?.username
        );
      case "assignedTo":
        return (
          data && data.find((user) => user._id === row[item.key])?.username
        );
      //  data&&<SelectUser id="assignee" handleChange={handleAssign} value={row[item.key]} options={[...data.map(d=>({label:d.username,_id:d._id}))]} />
      case "description":
        return row[item.key]?.substring(0, 30) + "...";
      case "dueDate":
        return moment(row[item.key]).fromNow();
      default:
        return row[item.key]?.toLocaleString();
    }
  };
  const handleSelectTask = (id: any) => {
    const task = rows.find((t) => t._id === id);
    if (task) {
      setSelectedRow(task);
      setOpenModal(true);
      setEdit(true);
    }
  };
  const handleClose = () => {
    setOpenModal(false);
    setEdit(false);
    setSelectedRow(null);
  };
  const handleSubmitForm = (task: Task) => {
    for (const key in task) {
      if (task[key] === "" || task[key] === null) {
        delete task[key];
      }
      if (task[key] === "N/A") {
        task[key] = null;
      }
    }
    if (edit && selectedRow) {
      updateTask({ ...task })
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    } else {
      createTask(task)
        .then((data) => {
          handleClose();
          setEdit(false);
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    }
  };
  const handleCreate = () => {
    setEdit(false);
    setSelectedRow(null);
    setOpenModal(true);
  };
  const handleDelete = (_id: string) => {
    if (window.confirm(`Are you sure you want to delete the task?`)) {
      deleteTask(_id)
        .then((res) => {
          setSelectedRow(null);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    }
  };
  const handleFilterChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setSelectedFilter(value);
    let filtered;
    switch (value) {
      case "all":
        setTableData(rows);
        break;
      case "mytasks":
        filtered = rows.filter((task) => task.assignedTo === currentUser._id);
        setTableData(filtered);
        break;
      case "created":
        filtered = rows.filter((task) => task.createdBy === currentUser._id);
        setTableData(filtered);
        break;
      case "done":
        filtered = rows.filter((task) => task.status === "Completed");
        setTableData(filtered);
        break;
      default:
        setTableData(rows);
        break;
    }
  };
  if (isLoading) return <Loader />;
  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        gap: 2,
        height: "100%",
        flexWrap: "nowrap",
      }}>
      {data && (
        <TaskForm
          submitHanlder={handleSubmitForm}
          edit={!!selectedRow}
          open={openModal}
          handleClose={handleClose}
          selectedTask={selectedRow}
          users={data}
        />
      )}
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
          borderRadius: "8px",

          width: "100%",
          height: "100%",
        }}>
        <Stack mx={1} direction={"row"} alignItems={"center"}>
          <Select
            sx={{
              m: 1,
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            value={selectedFilter}
            onChange={handleFilterChange}>
            <MenuItem value={"all"}>All Tasks</MenuItem>
            <MenuItem value={"mytasks"}>My Tasks</MenuItem>
            <MenuItem value={"created"}>Created Tasks</MenuItem>
            <MenuItem value={"done"}>Completed</MenuItem>
          </Select>
          <Button
            variant="outlined"
            startIcon={<TaskAlt />}
            onClick={handleCreate}>
            Create
          </Button>
        </Stack>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((item) => (
                <TableCell key={item.key}>{item.label}</TableCell>
              ))}
              <TableCell key={"actions"}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow
                onClick={() => handleSelectTask(row._id)}
                selected={selectedRow?._id === row._id}
                key={row._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}>
                {headers.map((item) => (
                  <TableCell key={item.key}>{renderCells(item, row)}</TableCell>
                ))}
                <TableCell
                  key={`actions-${row._id}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(row._id);
                  }}>
                  <Button startIcon={<Delete />} color="error" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
