import React from 'react'
import { useGetTasksQuery } from '../../services/tasks.service'
import { Box } from '@mui/material';
import TasksTable from '../../components/Tasks/Table';
import Loader from '../../components/common/Loader';
import { useNavigate } from 'react-router-dom';

function TasksPage() {
    const { data, error, isLoading } = useGetTasksQuery('Tasks');
    const navigator = useNavigate()

    if(error){
      navigator('/')
  }  
  if(isLoading){
      return <Loader/>
  }
  return (
    
   <Box height={'100%'} width={'100%'}>
   {<TasksTable rows={data!}/>}
   </Box>
  )
}

export default TasksPage