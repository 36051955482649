import { MomentInput } from "moment";
export const ROLES = ["admin", "employee"];
export const ASSIGN_ROLES = ["auto", "manual"];
export enum ACTIONS {
  WRITE = "WRITE",
  CREATE = "CREATE",
  READ = "READ",
  DELETE = "DELETE",
}
export type ActionType =
  | ACTIONS.DELETE
  | ACTIONS.READ
  | ACTIONS.WRITE
  | ACTIONS.CREATE
  | string;
export interface Permission {
  resource: string;
  actions: string[];
}

export enum MethodsMapping {
  GET = ACTIONS.READ,
  POST = ACTIONS.CREATE,
  PATCH = ACTIONS.WRITE,
  PUT = ACTIONS.WRITE,
  DELETE = ACTIONS.DELETE,
}
export interface User {
  [key: string]: string | Date | undefined | MomentInput | Permission[];
  username: string;

  role: string;

  email: string;

  tasks?: [];

  submissions?: [];

  transactions?: [];
  access_token?: string | null;
  assignRole?: string;
  createdAt?: MomentInput;
  permissions: Permission[];
  updatedAt?: MomentInput;
  _id: string;
}
