import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';



const MinimalLayout = () => (
    <Box sx={{ minHeight: '100vh' }} >

    <Outlet />
  <Box/>
    </Box>
);

export default MinimalLayout;
