import { Chip, Grid, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useState } from "react";
import RangeSelectorDatetime from '../common/RangeSelectorDatetime'
import Chart from 'react-apexcharts'
import { useGetFilteredTransactionsMutation } from "../../services/analytics.service";
import moment, { Moment } from 'moment'
const chartStyle = {
  height: "100%",
  width: "100%",
  flexDirection: "column",
  bgcolor: "#ffffff",
  p: 2,
  boxShadow: "0px 0px 55px 5px rgba(0, 0, 0, 0.05)",
  borderRadius: "8px",
};
const dataBar={
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ]
    };
function TransactionsChart() {
    const [transactions,setTransactions]=useState<any>({});
    const  [dataSource, setDataSource] = useState<string[]>(['createdAt']);
    const [pipeline, setPipeline] = useState({
        ranges:[{
            field: dataSource[0],
          start: moment().subtract(1,'weeks'),
          end: moment() 
        },
    ],
    "dynamicFilters": [],
    "groupOptions": {
        "aggregateField": "amount"
    }
    })
    const [chartData, setChartData] = useState<any>({
        categories:[],
        series:[]
    })
        const [getTranscations]=useGetFilteredTransactionsMutation();
      
       const getFilteredTranscations=async(pipeline:any)=>{
        return  await getTranscations(pipeline).then((data:any)=>{
             const items:any[]=data?.data[0]?.item;
             const amount=data?.data[0].amount;
             const count=data?.data[0].count;
             const groupedValues=Object.groupBy(items,({createdAt}:any)=>createdAt.split('T')[0]);
             const keys=Object.keys(groupedValues)
             const categories=keys.sort((a,b)=>{
                 return new Date(a).valueOf()-new Date(b).valueOf()
             })
             return {categories,groupedValues,amount,count}
             
             }).catch((err)=>{
                 console.log(err)
             })
     }


       const handleRangeChage=(range:{start:Moment|null,end:Moment|null})=>{
        setPipeline({
            ...pipeline,
            ranges:[{
                field: dataSource[0],
                start:range.start?.toISOString() as any,
                end:range.end?.toISOString() as any ,
            }]
        })
        
        }
        useEffect(() => {
            const getTrasnacionsData=async()=>{
                const transactions=await getFilteredTranscations(pipeline) as any;
                setTransactions(transactions)
            }
               getTrasnacionsData()
        }, [dataSource,pipeline]);

    useEffect(() => {
            if(transactions?.categories){

                const categories = [...transactions.categories].sort((a, b) => {
                  return new Date(a).valueOf() - new Date(b).valueOf();
                });
                setChartData({
                    categories:Array.from(new Set(categories)),
                    series:[
                        { name: 'Count',
                        data:categories.map((item:any)=>transactions.groupedValues[item]?.length)
                    },
                        { name: 'Amount',
                        data:categories.map((item:any)=>transactions.groupedValues[item].map((a:any)=>a.amount))
                    }
                ]
            })
        }
    

        
    
       
     
    }, [dataSource,pipeline,transactions])
    
        const handleAddSource=(source:'createdAt'|'transactionDate')=>{

            let data=new Set([source])
           setDataSource(Array.from(data)) 
        }
  return (
    <Grid item container flex={3} sx={chartStyle} flexDirection={"column"}>
      <Grid
        item
        container
        gap={1}
        height={"40px"}
        flexDirection={"row"}
        width={"100%"}
        alignItems={"center"}>
        <RangeSelectorDatetime onRangeChange={handleRangeChage} />

        <Stack sx={{ marginRight: "auto" }} direction={"row"} spacing={0.5}>
          {["createdAt", "transactionDate"].map((source: any) => (
            <Chip
              sx={{ borderRadius: "8px" }}
              key={source}
              label={source}
              component={"button"}
              onClick={() => handleAddSource(source)}
              color={dataSource.includes(source) ? "primary" : "default"}
            />
          ))}
        </Stack>
        <Paper sx={{ p: 1 }}>
          <Typography variant="subtitle1">
            Total Count : {transactions?.count}
          </Typography>
        </Paper>
        <Paper sx={{ p: 1 }}>
          <Typography variant="subtitle1">
            Total Amount : {transactions?.amount}
          </Typography>
        </Paper>
      </Grid>
      <Grid item height={"100%"} flex={100}>
        <Chart
          options={{
            ...dataBar.options,
            xaxis: {
              categories: chartData.categories,
            },
          }}
          series={chartData.series}
          type="bar"
          height="100%"
        />
      </Grid>
    </Grid>
  );
}

export default TransactionsChart