import React from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Box,
  Paper
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Visibility ,VisibilityOff} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { login } from '../../store/slices/users.slice';
import { BASE_URL, envConfig } from '../../config';

// project import

// assets

// ============================|| FIREBASE - LOGIN ||============================ //
const LoginValidationSchema=Yup.object().shape({
    username: Yup.string().max(255).required('username is required'),
      // email: Yup.string().email('Must be a valid username').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required')
    })

const initialValue={
    username: '',
    // email:'',
  password: '',
  submit: null
}
const AuthLogin = () => {
    const navigator = useNavigate(); 
  const dispatch=useDispatch()
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event:any) => {
    event.preventDefault();
  };
  const formik = useFormik({
    enableReinitialize:true,
    initialValues:initialValue,
    validationSchema:LoginValidationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
            let userData={  
                username:values.username,
                password: values.password}
            setStatus({ success: false });
            setSubmitting(true);
            let res=await fetch(BASE_URL+'auth/login',{
                credentials: 'include',
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    "api-key":envConfig.API_KEY
                  },
                method:'POST',
                body:JSON.stringify(userData)
            })
            if(res.ok){
            const user=await res.json()
            console.log(user)
            dispatch(login(user))
                navigator('/')
                setSubmitting(false);
            }else if(res.status>=400){
                setErrors({ submit: res.statusText
                });
            }
          } catch (err:any) {
            console.log(err)
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
      },
  });



  return (
   
      <Box
      height={'100%'}
      display={'flex'}
      sx={{
        margin:'auto',
        padding:'16px'
      }}
      alignItems={'center'}
      justifyContent={'center'}
      >
       <Paper sx={{p:2}}>
       <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container width={400} height={'100%'} spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-login">User Name</InputLabel>
                  <OutlinedInput
                    id="username-login"
                    type="text"
                    value={formik.values.username}
                    name="username"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter username "
                    fullWidth
                    error={Boolean(formik.touched.username && formik.errors.username)}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <FormHelperText error id="standard-weight-helper-text-username-login">
                      {formik.errors.username}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              {/* <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-login">Email Address</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="email"
                    value={formik.values.email}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter email address"
                    fullWidth
                    error={Boolean(formik.touched.email &&formik.errors.email)}
                  />
                  {formik.touched.email &&formik.errors.email && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {formik.errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid> */}
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-login">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(formik.touched.password && formik.errors.password)}
                    id="-password-login"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Enter password"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
{/* 
              <Grid item xs={12} sx={{ mt: -1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(event) => setChecked(event.target.checked)}
                        name="checked"
                        color="primary"
                        size="small"
                      />
                    }
                    label={<Typography variant="h6">Keep me sign in</Typography>}
                  />
                  <Link variant="h6" component={RouterLink} to="" color="text.primary">
                    Forgot Password?
                  </Link>
                </Stack>
              </Grid> */}
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                {/* <AnimateButton> */}
                  <Button disableElevation disabled={formik.isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                    Login
                  </Button>
                {/* </AnimateButton> */}
              </Grid>
             
           
            </Grid>
          </form>
       </Paper>
      </Box>

  );
};

export default AuthLogin;
