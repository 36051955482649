import { useRoutes } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export default function AppRoutes() {
  const user = useSelector((state: RootState) => state.user);

  const isLoggedIn = !!user.access_token;
  return useRoutes([MainRoutes(isLoggedIn), LoginRoutes]);
}
