import React from 'react'
import { Box } from '@mui/material';
import Loader from '../../components/common/Loader';
import { useGetStudentsQuery } from '../../services/students.service';
import StudentsTable from '../../components/Students/Table';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../../services/users.service';

function StudentsPage() {
    const navigator = useNavigate()

    const { data, error, isLoading } = useGetStudentsQuery('Students');
    const { data:users, error:usersError, isLoading:isLoadingUsers } = useGetUsersQuery('Users');

    const isLoadingData=isLoading||isLoadingUsers

    if(usersError||error){
        navigator('/')
    }

    return (
    
        <Box height={'100%'} width={'100%'}>
        {isLoadingData?<Loader/>: <StudentsTable users={users!} rows={data!}/>}
        </Box>
       )
}

export default StudentsPage