import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useFormikContext } from 'formik';

function SelectField({control,otherProps}:{control:any,otherProps:any}) {
  const formik = useFormikContext();
  const fieldProps=formik.getFieldProps(control.name);
  const value=fieldProps.value||''
  return (
    <FormControl  {...otherProps}>
    <InputLabel  id={control.id}>{control.label}</InputLabel>
    <Select
    variant="filled"
    labelId={control.id}
    {...fieldProps}
        {...control.additional}
    value={value}
    >
        <MenuItem value="" selected={value===''}>
            <em>None</em>
          </MenuItem>
     {control.options.map((option:any)=>(
         <MenuItem value={option.value} {...option.additional} key={option.value}>{option.label}</MenuItem>
     ))}
    </Select>
  </FormControl>
  )
}

export default SelectField