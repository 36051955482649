import MainLayout from "../layout/MainLayout";
import Dashboard from "../pages/Dashboard";
import TasksPage from "../pages/Tasks";
import UsersPage from "../pages/Users";
import SubmissionsPage from "../pages/Submissions";
import StudentsPage from "../pages/Students";
import TransactionsPage from "../pages/Transactions";
import { Navigate } from "react-router-dom";
import AnalyticsPage from "../pages/Analytics";
import StudentDetails from "../components/Students/StudentDetails";
import InvoicesPage from "../pages/Invoice";
const MainRoutes = (isLoggedIn: boolean) => ({
  path: "/",
  element: isLoggedIn ? <MainLayout /> : <Navigate to={"/login"} replace />,
  children: [
    { path: "/", element: <Dashboard /> },
    { path: "/tasks", element: <TasksPage /> },
    { path: "/users", element: <UsersPage /> },
    { path: "/submissions", element: <SubmissionsPage /> },
    {
      path: "/students",
      element: <StudentsPage />,
    },
    { path: "/students/:id", element: <StudentDetails /> },
    { path: "/transactions", element: <TransactionsPage /> },
    { path: "/analytics", element: <AnalyticsPage /> },
    { path: "/invoices", element: <InvoicesPage /> },
  ],
});
 
export default MainRoutes
